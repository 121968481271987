import { Avatar, Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";

export const PaymentCardsUser = ({ getData }) => {
  return (
    <Box bg="#FFF" w="100%" h="" mb="10px" p="10px" borderRadius={"10px"}>
      <Flex
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Flex alignItems={"center"}>
          <Avatar
            src={getData?.userId?.image}
            name={
              getData?.userId?.firstName
                ? `${getData?.userId?.firstName} ${getData?.userId?.lastName}`
                : ""
            }
          />
          <Box ml="20px">
            <Tooltip
              label={
                getData?.userId?.firstName
                  ? `${getData?.userId?.firstName} ${getData?.userId?.lastName}`
                  : "N/A"
              }
            >
              <Text textAlign={"left"} width={"120px"} isTruncated>
                {getData?.userId?.firstName
                  ? `${getData?.userId?.firstName} ${getData?.userId?.lastName}`
                  : "N/A"}
              </Text>
            </Tooltip>
          </Box>
        </Flex>

        <Box>
          <Text textAlign={"left"} width={"100px"} isTruncated>
            {dayjs(getData?.createdAt).format("DD MMMM, YYYY")}
          </Text>{" "}
          <Text
            color="#BBBBBB"
            fontSize={"9px"}
            textAlign={"left"}
            width={"100px"}
            isTruncated
          >
            Payment Date
          </Text>
        </Box>
        <Flex
          bg={"#EEE4FE"}
          borderRadius={"10px"}
          w="84px"
          h="23px"
          px="5px"
          alignItems={"center"}
          justifyContent={"center"}
          textTransform={"uppercase"}
        >
          {/* <Text>Consultation</Text> */}
          {/* <Text>{dayjs(getData?.createdAt).format("DD-MMMM-YYYY")}</Text> */}
          <Text justifyContent={"center"} fontSize={"10px"}>
            Subscribed
          </Text>
        </Flex>
        {/* <Box>
          <Text>{dayjs(getData?.createdAt).format("h:mm A")}</Text>
        </Box> */}
        <Box>
          <Text>{`\u20A6${getData?.amount}`}</Text>
          <Text color="#BBBBBB" fontSize={"9px"}>
            Amount
          </Text>
        </Box>
        <Flex
          bg={"#EEE4FE"}
          borderRadius={"10px"}
          w="84px"
          h="23px"
          px="5px"
          justifyContent={"center"}
        >
          <Text mt="5px" justifyContent={"center"} fontSize={"10px"}>
            {getData?.status}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
