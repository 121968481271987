import { Box, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiFillRightSquare } from "react-icons/ai";
import { Link } from "react-router-dom";

export const Boxe = (props) => {
  const { name, id } = props?.test || {};

  return (
    <Box>
      <Box
        bg="#F3F3F3"
        p={["20px"]}
        rounded={"15px"}
        mx={["30px", "0", "0", "0"]}
      >
        <Tooltip label={name}>
          <Text
            color="#200960"
            fontSize="25px"
            fontWeight={"bold"}
            py="10px"
            isTruncated
            w="200px"
          >
            {name}
          </Text>
        </Tooltip>
        <Text color="#929292" pb="10px" fontFamily={"15px"}>
          View Report{" "}
        </Text>
        <Link to={`/generalReport/${name}/${id}`}>
          <AiFillRightSquare
            color="#200960"
            size={"1.9em"}
            style={{ borderRadius: "50px" }}
          />
        </Link>
      </Box>
    </Box>
  );
};
