import http, { AUTH_ROUTES } from "../../../services/api";

export const getUserPaid = async (setUserPaid) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_USER_PAYIN);
    setUserPaid(result?.data);
  } catch (e) {
    return null;
  }
};

export const getUserPayIn = async (filter, setUserPayIn) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_USER_PAYIN);
    if (filter === "all") {
      setUserPayIn(result?.data?.totalPayin?.data?.totalPay_in[0]?.TotalAmount);
      //data.totalPayin.data.totalPay_in[0].TotalAmount
    } else if (filter === "monthly") {
      setUserPayIn(
        result?.data?.totalPayin?.data?.monthlyPayInRecord[0]?.TotalAmount
      );
    } else {
      setUserPayIn(0);
    }
  } catch (e) {}
};
