import { Box, Divider, Select, Text } from "@chakra-ui/react";
import React from "react";
import { TopDoctorCard } from "./TopDoctorCard";

export const TopDoctorsCard = ({ getData, total, setfilter }) => {
  return (
    <Box borderRadius={"10px"} p="30px" bg={"#F6F4F9"} overflowY="scroll">
      <Box textAlign={"left"}>
        <Select
          color={"#C8B6E2"}
          border="none"
          _focus={{ boxShadow: "none" }}
          width="110px"
          maxW="110px"
          size="xs"
          fontSize={"14px"}
          onChange={(e) => setfilter(e.target.value)}
        >
          <option value={"all"}>Total</option>
          <option value={"monthly"}>This month</option>
        </Select>
      </Box>
      <Box mt="0px">
        <Text color={"#403058"} fontSize={"56px"} fontWeight="semibold">
          {total || 0}
        </Text>
        <Text color={"#C8B6E2"}>Therapists</Text>
      </Box>
      <Divider />
      <Box mt="20px">
        <TopDoctorCard getData={getData?.slice(0, 5) || []} />
      </Box>
    </Box>
  );
};
