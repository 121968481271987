import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Chartcard } from "./Chartcard";
import { Card } from "./components/Card";
import { Topbar } from "./components/Topbar";
import { getTestName, getTestReport } from "./services/getTestReport";
import FullPageLoader from "../../components/fullPageLoader";

export const GeneralReport = ({ match }) => {
  const [tests, setTests] = useState([]);
  const [testName, setTestName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadFigures, setLoadFigures] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [testTimePeriod, setTestTimePeriod] = useState("monthly");

  const name = match.params.name;
  const testId = match.params.testId;

  useEffect(() => {
    setLoading(true);
    getTestReport(setTests, testId, testTimePeriod, setLoading);
    getTestName(setTestName);
  }, [name, testId, setRefresh, testTimePeriod]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box bg="#F6F4F9" rounded={"20px"} p="30px">
      <Topbar
        setTestTimePeriod={setTestTimePeriod}
        testTimePeriod={testTimePeriod}
        setLoadFigures={setLoadFigures}
        tests={tests}
        setDateRange={setTests}
        name={name}
        setRefresh={setRefresh}
      />
      <Card tests={tests} name={name} loadFigures={loadFigures} />
      <Chartcard tests={tests} chartt={tests?.totalPatientThatTookTest} />
    </Box>
  );
};
