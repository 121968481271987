import http, { AUTH_ROUTES } from "../../../services/api";

export const searchUsers = async (payload, setLoading, setSearchResult) => {
  try {
    const data = await http.get(AUTH_ROUTES.SEARCH_USER(payload));
    setSearchResult(data?.data?.search?.data);
    setLoading(false);
    
  } catch (e) {
    setLoading(false);
    setSearchResult([]);
  }
};
