//import { getFilterString } from "../../../services/mixin";
import http, { AUTH_ROUTES } from "../../../services/api";
import { DATA_ROWS } from "../../../app/constants";

export const getReviews = async (skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = limit * skip;
  const params = {
    skip: page,
    limit,
  };
  const result = await http.get(AUTH_ROUTES.GET_ALL_REVIEWS(params));
  return result?.data?.ratings?.data;
};

export const getTopDoctors = async (setAllTopDoctors) => {
  // let filterString = getFilterString(filter)
  try {
    const result = await http.get(AUTH_ROUTES.GET_TOP_DOCTOR);
    setAllTopDoctors(result?.data?.topDoctor?.data?.topDoctors);
  } catch (e) {
    return null;
  }
};

export const searchDoctorRating = async (setReviews, search) => {
  try {
    const result = await http.get(AUTH_ROUTES.SEARCH_DOCTOR_RATING(search));
    setReviews(result?.data?.search?.data);
  } catch (e) {
    return null;
  }
};
