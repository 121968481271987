import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  useDisclosure,
  Flex,
  Stack,
  Button,
  Input,
  Textarea,
  Image,
  ModalFooter,
} from "@chakra-ui/react";
//import { HiBriefcase } from "react-icons/hi";
import vidupload from "../../../assets/vidupload.svg";

import { IoArrowBackOutline } from "react-icons/io5";
import { uploadVideo } from "../services";
import { useState } from "react";
import { HiBriefcase } from "react-icons/hi";
import { useRef } from "react";

export const TakeTestModal = ({ props, setRefresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const uploadImageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("videoTitle", videoTitle);
    formData.append("videoDescription", videoDescription);
    formData.append("videoUrl", videoUrl);
    formData.append("image", image);

    uploadVideo(formData).then(() => {
      setLoading(false);
      setRefresh(true);
      onClose();
    });
  };

  return (
    <Box pt={["70px", ""]}>
      <Button
        onClick={onOpen}
        bg="#403058"
        _hover={{ background: "#403058" }}
        size="md"
        color="#FFFFFF"
        w={["130px", "150px"]}
      >
        + Add Video
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"5xl"}>
        <ModalOverlay />
        <ModalContent py={["100px", ""]} pt={["100px", "100px", ""]}>
          <ModalBody textAlign={"center"}>
            <Flex justifyContent={"flex-start"}>
              <Button
                _focus={{ boxShadow: "none" }}
                onClick={onClose}
                leftIcon={<IoArrowBackOutline />}
                colorScheme="#C8ADC8"
                variant="outline"
              >
                Back
              </Button>
            </Flex>
            {/* The flex holding the three flex box */}
            <Flex py={["20px"]} px={"20px"}>
              <Flex direction={["column", "column", "row", "row"]}>
                <Flex direction={"column"} mt="23px">
                  <Stack align="flex-start">
                    <Text fontSize="sm" color="#352848" mt="30px">
                      Video Title
                    </Text>
                    <Input
                      //w="100%"
                      //w={["", "", "463px"]}
                      //h={["23px", "", "58px"]}
                      value={videoTitle}
                      onChange={(e) => setVideoTitle(e?.target?.value)}
                    />
                  </Stack>
                  <Stack align="flex-start">
                    <Text fontSize="sm" color="#352848" mt="30px">
                      Video Description
                    </Text>
                    <Textarea
                      h={["100px", "110px", "100px", "126px"]}
                      value={videoDescription}
                      onChange={(e) => setVideoDescription(e.target.value)}
                    />
                    <Text fontSize="sm" color="#352848" mt="30px">
                      Video Url
                    </Text>
                    <Text fontSize="sm" color="#352848" mt="30px">
                      Upload the video link here
                    </Text>
                    <Input
                      //h={["23px", "", "58px"]}
                      value={videoUrl}
                      onChange={(e) => setVideoUrl(e.target.value)}
                    />
                  </Stack>
                </Flex>
                <Flex direction={"column"} mt="45px" textAlign={"left"} ml={["0", "83px"]}>
                  <Text ml={["0", "22px"]} color="#9F9F9F">
                    Course thumbnail
                  </Text>
                  <Box ml={["0", "24px"]} direction={"column"} position="relative">
                    <Text maxWidth={"50%"} py="12px" color="#9F9F9F">
                      Image not more than 600X600 or less than 300x300
                    </Text>
                    <Button
                      leftIcon={<HiBriefcase />}
                      bg="#5C2BA8"
                      _hover={{ background: "#5C2BA8" }}
                      color="#FFFFFF"
                      w="125px"
                      h="48px"
                      placeholder="Upload"
                      onClick={() => uploadImageRef?.current?.click()}
                    >
                      Upload
                    </Button>
                    <input
                      ref={uploadImageRef}
                      style={{ display: "none" }}
                      className="photo"
                      accept="image/png, image/jpeg"
                      type="file"
                      name="file"
                      onChange={(e) => {
                        setImage(e?.target?.files[0]);
                        setFile(URL.createObjectURL(e?.target?.files[0]));
                      }}
                    />
                  </Box>
                </Flex>
                <Flex direction={"column"} ml={["0", "0", "-64px"]}>
                  <Flex
                    mt="70px"
                    alignItems={"center"}
                    justifyContent={"center"}
                    //bg="#F5F9FF"
                    w={["150px", "190px", "213px"]}
                    h={["", "", "190px", "213px"]}
                    border={"1px solid #C3D2E8"}
                    borderRadius="20px"
                    p="15px"
                  >
                    {file ? <Image src={file} alt="" w="100%" /> : <Image src={vidupload} alt="" />}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="#5C2BA8"
              _hover={{ background: "#5C2BA8" }}
              color="#FFFFFF"
              w="157px"
              h="50px"
              isLoading={loading}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
