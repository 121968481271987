import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
// import { useHistory } from "react-router-dom"
import { authenticateCode } from "../service/forgotPassword";
// import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// import { loginUser } from '../service/loginUser';

export const AuthenticateCode = ({ email }) => {
  const [show] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = async () => {
    const payload = {
      password,
      otp: code,
      email,
    };
    setLoading(true);
    await authenticateCode(payload, setLoading);
    setLoading(false);
  };

  return (
    <Box>
      {/* <Flex width="100%">
        <Box width="50%">
          <Box>
            <Image />
          </Box>
        </Box> */}
      <Box width={"100%"} p="100px">
        <Heading
          fontFamily={"Gilroy-Bold"}
          mb="20px"
          fontStyle="italic"
          color="#2A2047"
        >
          Authentication code
        </Heading>
        <Text fontSize="18px" color="#929292" maxWidth="400px">
          Input the code sent to your email address
        </Text>
        <FormControl my="40px">
          <FormLabel htmlFor="email" color="#929292">
            Input Code
          </FormLabel>
          <Input
            height="50px"
            borderRadius={"10px"}
            placeholder="Enter the code sent to you"
            type={show ? "text" : "password"}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </FormControl>
        <FormControl my="40px">
          <FormLabel htmlFor="password" color="#929292">
            Enter new password
          </FormLabel>
          <Input
            height="50px"
            borderRadius={"10px"}
            placeholder="Enter new password"
            type={show ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <Button
          mt="20px"
          bg="#2A2047"
          color="#fff"
          borderRadius={"10px"}
          width="100%"
          height="50px"
          isLoading={loading}
          onClick={handleLogin}
          _hover={{ bg: "#5C2BA8" }}
        >
          Submit
        </Button>
        {/* <Link to="/forgot-password" style={{ color: "#ED819C", marginTop: "5px" }}>Resend Code</Link> */}
      </Box>
      {/* </Flex> */}
    </Box>
  );
};
