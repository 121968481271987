import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import dayjs from "dayjs";

export const NotificationCard = ({ key, data }) => {
  return (
    <Box p="20px 30px" key={key} mt="10px">
      <Flex align={"center"}>
        <Box ml="20px">
          <Text color="#403058" fontWeight="bold" fontSize="1.2em">
            {data?.title}
          </Text>
          <Text color="#403058">{data?.message}</Text>
          <Text fontSize={".8em"} color="#929292">
            {dayjs(data?.createdAt).format("hh:mm a")}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
