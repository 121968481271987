export const AUTH_ROUTES = {
  LOGIN: "/admin/login",
  FORGOT_PASSWORD: "/admin/forgot-password",
  RESET_PASSWORD: "/admin/reset-password",
  GET_USER: (userId) => `/admin?id=${userId}`,
  GET_USERS: `/users`,
  GET_ALL_USERS: (skip, limit) =>
    `/users?skip=${skip}&limit=${limit}&sort=desc`,
  GET_TOTAL_USERS: "/users",
  GET_MONTHLY_TOTAL_USERS: "/admin/getall-user-monthly",
  GET_ALL_REVIEWS: ({ skip, limit }) =>
    `admin/doctors/ratings?skip=${skip}&limit=${limit}`,
  //GET_TOP_DOCTOR_RATING: `/admin/top-doctors`,
  UPDATE_USER: (userId) => `/users/${userId}/update`,
  DELETE_USER: (userId) => `/users/${userId}/delete`,

  GET_TEST_TAKEN: `/admin/all-test`,
  GET_TEST_TAKEN_MONTHLY: "/admin/getall-test-monthly",
  GET_MONTHLY_TEST: `/admin/getall-test-monthly`,
  GET_MONTHLY_USER: `/admin/getall-user-monthly`,

  GET_TOP_ASSESSMENT: `/admin/get-top-assessment`,
  GET_TODAY_APPOINTMENT: `/admin/today-appointment`,

  // GET_TEST_REPORT: (test) => `/admin/get-test-report-details/${test}`,
  GET_TEST_REPORT: (testId, timePeriod) =>
    `/admin/test-analytics?testId=${testId}&timePeriod=${timePeriod}`,
  GET_TEST_NAME: `/admin/get-test-report`,
  GET_REPORT_DROPDOWN: (dateRange, test) =>
    `/admin/get-test-report-details/${test}/${dateRange}`,

  // FORGOT_PASSWORD: "/forgot-password",
  GET_TOP_DOCTOR: "/admin/top-doctors",
  ADD_USER: "/admin/add-user",
  GET_DOCTORS: ({ skip, limit }) =>
    `/doctors?skip=${skip}&limit=${limit}&sort=desc`,
  GET_ALL_DOCTORS: (filter) => `/doctors${filter}?sort=desc`,
  GET_TOTAL_DOCTORS: "/doctors",
  GET_MONTHLY_TOTAL_DOCTORS: "/admin/getall-user-monthly",

  ADD_DOCTOR: "/admin/add-doctor",
  UPDATE_DOCTOR: (id) => `/doctors/${id}`,

  GET_MONTHLY_DOCTORS: "/admin//getall-doctors",

  DELETE_DOCTOR: (doctorId) => `/admin/delete-doctor/${doctorId}`,
  SEARCH_DOCTOR: (searchTerm) => `/admin/search-doctor?search=${searchTerm}`,
  SEARCH_USER: (search) => `/admin/search-user?search=${search}`,
  SEARCH_DOCTOR_RATING: (search) =>
    `/admin/search-rating-report?search=${search}`,
  MEETING_SUMMARY_SEARCH_DOCTOR: (search) =>
    `/admin/meeting-summary-search-doctor?search=${search}`,

  // GET_NOTIFICATIONS: (recipientId) =>
  //   `/notifications?recipientId=${recipientId}`,

  GET_NOTIFICATIONS: `/admin/notifications?sort=desc`,
  //get-withdrawal now payout request
  //GET_WITHDRAWALS: `/admin/get-withdrawals?sort=desc`,
  GET_WITHDRAWALS: `/admin/total-payout-request?sort=desc`,
  GET_PAYOUTS: `admin/get-total-payout?sort=desc`,
  UPDATE_PAYOUT_STATUS: (payoutId) => `/admin/update/${payoutId}`,
  GET_CONSULTATION: `admin/consultation`,
  GET_SUBSCRIPTION_LIST: `users/subscription-list`,
  GET_USER_PAYIN: `/admin/alluser-total-pay-in?sort=desc`,

  UPDATE_SUBSCRIPTION: (subscriptionId) =>
    `/admin/subscription/${subscriptionId}`,

  ADMIN_SEARCH_USER: (searchTerm) => `/admin/search-user?search=${searchTerm}`,

  ADMIN_SEARCH_DOCTOR: (searchTerm) =>
    `/admin/search-doctor?search=${searchTerm}`,
  GET_VIDEO: ({ skip, limit }) =>
    `/admin/get-video-upload?skip=${skip}&limit=${limit}&sort=desc`,
  UPLOAD_VIDEO: `/admin/video-upload`,
  DELETE_VIDEO: (videoId) => `/admin/delete-video/${videoId}`,
  UPDATE_VIDEO: (videoId) => `/admin/video-update/${videoId}`,

  MEETING_SUMMARY: ({ skip, limit }) =>
    `/admin/meeting-summary?sort=desc&skip=${skip}&limit=${limit}`,
};
