import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  //  useDisclosure,
  Box,
  //  Button,
  MenuDivider,
  //  Tooltip,
  //  Text
} from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai";
// import { FaEdit } from "react-icons/fa"
import { BiDotsHorizontalRounded } from "react-icons/bi";
// import CautionAlertDialog from '../../../components/CautionDialog'
// import { UpdateUsersModal } from './UpdateUsersModal'
import { deleteUser } from "../service/deleteUser";

export const MenuBox = ({ data, setRefresh }) => {
  // setLoading(true)
  // const { isOpen } = useDisclosure();
  const handleDelete = () => {
    deleteUser(data?._id)
      .then(() => {
        // setLoading(false);
        setRefresh(true);
      })
      .catch((e) => {
        return null;
      });
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton isActive={isOpen} as={Box} cursor="pointer">
            {/* {isOpen ? 'Close' : 'Open'} */}
            <BiDotsHorizontalRounded size="26px" cursor={"pointer"} />
          </MenuButton>
          <MenuList>
            {/* FULLY FUNCTIONAL BUT COMMENTED OUT BECAUSE ADMIN SHOULDNT BE ABLE TO EDIT A USER'S ACCOUNT */}
            {/* <MenuItem>
            <UpdateUsersModal data={data} setRefresh={setRefresh} />
          </MenuItem> */}
            <MenuDivider />
            <MenuItem
              icon={<AiOutlineDelete size={"18px"} />}
              onClick={() => handleDelete()}
            >
              Delete
              {/* <CautionAlertDialog
              icon={
                <Tooltip label="delete user" aria-label="A tooltip">
                  <Text className="red small" color="#000">
                    <AiOutlineDelete />
                    Delete
                  </Text>
                </Tooltip>
              }
              cautionTitle="Are you sure you want to delete this user?"
              mt="5px"
              small={true}
              // onContinue={() => handleDelete(data._id)}
            /> */}
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
