import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/media-query";
import { Redirect, Route, Switch } from "react-router";
import { PROTECTED_PATHS } from "./constants";
import { Navigation } from "../components/nav";
import SideBar from "../components/layout/sidebar";
import { Dashboard } from "../pages/dashboard/";
import { Doctors } from "../pages/doctors";
import { Finance } from "../pages/finance";

// import { StepsStyleConfig as Steps } from "chakra-ui-steps";
// import { TakeTest } from "../pages/test/components/TakeTest";
// import { Invite } from "../pages/test/components/Invite";
// import { Chats } from "../pages/chats";
import { Notifications } from "../pages/notifications";
import { Users } from "../pages/users";
import { Reviews } from "../pages/reviews";
import { Subscription } from "../pages/subscription";
//import { VideoUpload } from "../pages/video-upload/components/services";
import { TestUpload } from "../pages/test-upload";
import { TestReport } from "../pages/test-report";
import { MeetingSummary } from "../pages/meeting-summary";
import { GeneralReport } from "../pages/general-report";
import { connect, disconnect } from "../services/socket";
import { useSelector } from "react-redux";
import FullPageLoader from "../components/fullPageLoader";

// const theme = extendTheme({
//   components: {
//     Steps,
//   },
// });

const AuthenticatedApp = () => {
  const [toggleSide, setToggleSide] = useState(false);
  const [showSidebar] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileScreen] = useMediaQuery("(max-width: 600px)");
  const id = useSelector((state) => state?.auth?.id);
  const {
    DASHBOARD,
    TESTREPORT,
    FINANCE,
    USERS,
    DOCTORS,
    REVIEWS,
    NOTIFICATIONS,
    //VIDEOUPLOAD,
    SUBSCRIPTION,
    TESTUPLOAD,
    MEETINGS,
    GENERALREPORT,
  } = PROTECTED_PATHS;

  const handleToggle = () => {
    setToggleSide((initial) => !initial);
  };

  useEffect(() => {
    if (isMobileScreen) {
      setToggleSide((initial) => !initial);
    }
  }, [isMobileScreen]);

  useEffect(() => {
    connect({ name: id, type: "Admins" }).then(() => {
      setIsLoading(false);
    });
    return disconnect;
  }, [id]);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box className="App" display="flex">
      <Box className={`app-container`}>
        <Box
          className="app-sidebar"
          display={`${toggleSide ? "none" : "block"}`}
        >
          {showSidebar ? <SideBar toggle={handleToggle} /> : ""}
        </Box>
        {/* app right */}
        <Box w="100%" h="100%">
          <Navigation
            toggle={handleToggle}
            SideBarActive={toggleSide}
            showSidebar={showSidebar}
          />
          <Box
            width="100%"
            maxWidth="100%"
            mt="90px"
            padding={["5px", "10px", "20px"]}
          >
            <Switch>
              <Route path={"/"} exact component={Dashboard} />
              <Route path={DASHBOARD} exact component={Dashboard} />
              <Route path={TESTREPORT} exact component={TestReport} />
              <Route path={FINANCE} exact component={Finance} />
              <Route path={NOTIFICATIONS} exact component={Notifications} />
              <Route path={USERS} exact component={Users} />
              <Route path={DOCTORS} exact component={Doctors} />
              {/* <Route path={CHATS} exact component={Chats} /> */}
              <Route path={REVIEWS} exact component={Reviews} />
              {/*<Route path={VIDEOUPLOAD} exact component={VideoUpload} />*/}
              <Route path={TESTUPLOAD} exact component={TestUpload} />
              <Route path={MEETINGS} exact component={MeetingSummary} />
              <Route path={SUBSCRIPTION} exact component={Subscription} />
              <Route path={GENERALREPORT} exact component={GeneralReport} />
              <Redirect from="/*" to={DASHBOARD} />
            </Switch>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthenticatedApp;
