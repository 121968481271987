import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";

export const PaymentCards = ({ getData }) => {
  return (
    <Box bg="#FFF" w="100%" h="" mb="10px" p="10px" borderRadius={"10px"}>
      <Flex
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Avatar name={getData?.doctorId?.name} />
        </Box>
        <Box>
          <Text>{getData?.doctorId?.name || "N/A"}</Text>
        </Box>
        <Box>
          <Text>{dayjs(getData?.createdAt).format("DD MMMM, YYYY")}</Text>{" "}
          <Text color="#BBBBBB" fontSize={"9px"}>
            Request Date
          </Text>
        </Box>
        <Box>
          <Text>
            {getData?.status === "PAID"
              ? dayjs(getData?.updatedAt).format("DD MMMM, YYYY")
              : "--"}
          </Text>
          <Text color="#BBBBBB" fontSize={"9px"}>
            Payout Date
          </Text>
        </Box>
        <Box>
          <Text>{dayjs(getData?.createdAt).format("h:mm a")}</Text>
        </Box>
        <Box>
          <Text>{`\u20A6${getData?.amount}`}</Text>
        </Box>
        <Flex
          px="8px"
          bg={"#C9FFFB"}
          borderRadius={"10px"}
          w="54px"
          h="23px"
          justifyContent={"center"}
        >
          <Text mt="5px" fontSize={"10px"}>
            {getData?.status}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
