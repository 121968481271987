import { Box, Divider, HStack, Text, Flex } from "@chakra-ui/react";
import { PaginatedTable } from "../../components/PaginatedTable";
import { SearchBar } from "../../components/SearchBar";

import React, { useEffect, useState } from "react";
import { getMeetings, searchUsers } from "./services";

import { Table } from "./components/Table";
import FullPageLoader from "../../components/fullPageLoader";

export const MeetingSummary = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [meeting, setMeeting] = useState([]);

  const [loading, setLoading] = useState(true);

  const handleSearch = (keyword) => {
    setSearchTerm(keyword);
    searchUsers(keyword, setLoading, setFilteredUsers);
  };

  useEffect(() => {
    getMeetings(setMeeting, setLoading);
  }, []);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box bg="#EFEDF3">
      <Divider orientation="horizontal" />
      <Flex justify="space-between" align="center">
        <HStack>
          <Text fontSize="5xl" fontWeight="extrabold" color="#9E8FB5">
            {meeting?.meetingSummaryCount}
          </Text>
          <Text fontSize="md" color="#BBBBBB" fontWeight="semibold">
            Meetings
          </Text>
        </HStack>
        <SearchBar
          placeholder={"Search Therapist"}
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </Flex>
      <Divider orientation="horizontal" />
      {loading ? (
        <FullPageLoader />
      ) : (
        <PaginatedTable
          columns={[
            "Profiles",
            "Therapist",
            "Patient",
            "Date",
            "Duration",
            "Meet Means",
          ]}
          bg="#EFEDF3"
          data={
            <Table
              getData={
                (filteredUsers?.search?.data?.length && searchTerm !== "") > 0
                  ? filteredUsers?.search?.data
                  : meeting?.meetingSummary || []
              }
            />
          }
          total={meeting?.meetingSummaryCount}
          length={meeting?.meetingSummary?.length}
          updateTable={async (page) =>
            getMeetings(setMeeting, setLoading, page)
          }
        />
      )}
    </Box>
  );
};
