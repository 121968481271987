import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const updateSubscription = async (
  payload,
  SubscriptionId,
  setLoading
) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_SUBSCRIPTION(SubscriptionId), payload);
    successNotifier("subscription successfully updated");
    setLoading && setLoading(false);
  } catch (e) {
    setLoading && setLoading(false);
    errorNotifier();
  }
};
