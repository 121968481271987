export const tableHeadData = [
  "Name",
  "Phone Number",
  "Email Address",
  "Address",
  "Package",
  //"Status",
  "Action",
];

