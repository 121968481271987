import {
  Box,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { PaginatedTable } from "../../../components/PaginatedTable";
import { Card } from "../../finance/components/Card";
import { PayoutRequests } from "../../finance/components/PayoutRequests";
import { TopDoctorsCard } from "./TopDoctorsCard";
import cardBg from "../images/cardBgs.svg";
import { getTopDoctors } from "../services";
import { getTotalUsers } from "../../users/service/getUsers";
import { getTotalDoctors } from "../../doctors/service/getDoctors";

export const FirstRow = ({
  getData,
  testTaken,
  data,
  tests,
  users,
  totalConsultations,
  refresh,
  setRefresh,
  setTestTakenFilter,
  setConsultationFilter,
}) => {
  const [topDoctors, setTopDoctors] = useState([]);
  const [totalUser, setTotalUsers] = useState(0);
  const [totalDoctor, setTotalDoctor] = useState(0);
  const [totalUserFilter, setTotalUserFilter] = useState("all");
  const [totalDoctorFilter, setTotalDoctorFilter] = useState("all");

  const getDoctors = useCallback(() => {
    getTopDoctors()
      .then((response) => {
        setTopDoctors([...response]);
        console.log(response);
      })
      .catch((e) => {
        return null;
      });
  }, []);

  useEffect(() => {
    getTotalDoctors(totalDoctorFilter)
      .then((response) => {
        setTotalDoctor(response);
      })
      .catch((e) => {
        return null;
      });
    getDoctors();
    getTotalUsers(totalUserFilter)
      .then((response) => {
        setTotalUsers(response);
      })
      .catch((e) => {
        return null;
      });
    //eslint-disable-next-line
  }, [
    getDoctors,
    getTotalDoctors,
    refresh,
    totalUserFilter,
    totalDoctorFilter,
  ]);

  return (
    <Box>
      <SimpleGrid spacing={6} width="100%" columns={{ base: 1, xl: 3 }}>
        <SimpleGrid spacing={3} columns={{ base: 1, sm: 2, lg: 7, xl: 2 }}>
          <GridItem colSpan={{ base: 1, lg: 2, xl: 1 }}>
            <Card
              noNairaSymbol
              setFilter={setTestTakenFilter}
              bgImg={cardBg}
              bg={"#5C2BA8"}
              paymetCaption={"Test Taken"}
              payment={testTaken}
              tests={tests}
            />
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 2, xl: 1 }}>
            <Card
              noNairaSymbol
              setFilter={setConsultationFilter}
              bg={"#F6F4F9"}
              paymetCaption={"Consultations"}
              payment={totalConsultations || 0}
              color={"#403058"}
            />
          </GridItem>
          <GridItem colSpan={{ base: 1, sm: 2, lg: 3, xl: 2 }}>
            <Box>
              <Card
                noNairaSymbol
                setFilter={setTotalUserFilter}
                bg={"#F6F4F9"}
                paymetCaption={"Registered Users"}
                payment={totalUser || 0}
                color={"#403058"}
              />
            </Box>
          </GridItem>
        </SimpleGrid>
        <GridItem colSpan={{ base: 1, xl: 2 }}>
          <SimpleGrid
            spacingX={{ base: 0, lg: 6 }}
            spacingY={4}
            style={{ marginTop: "0px" }}
            columns={{ base: 1, lg: 7 }}
          >
            <GridItem colSpan={3}>
              <TopDoctorsCard
                setfilter={setTotalDoctorFilter}
                total={totalDoctor || 0}
                getData={topDoctors}
                monthly={data}
              />
            </GridItem>
            <GridItem colSpan={{ base: 1, lg: 3, xl: 4 }}>
              {getData?.length > 0 ? (
                <PaginatedTable
                  noPagination
                  columns={[]}
                  bg="#F6F4F9"
                  h={"440px"}
                  my={"0"}
                  mt="0"
                  data={
                    <PayoutRequests getData={getData} setRefresh={setRefresh} />
                  }
                />
              ) : (
                <>
                  <Heading
                    fontFamily={"Gilroy-Bold"}
                    textAlign="center"
                    color="#403058"
                  >
                    Payout Requests
                  </Heading>
                  <Flex
                    width="100%"
                    height={"100%"}
                    direction={"column"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text>No Payout Request</Text>
                  </Flex>
                </>
              )}
            </GridItem>
          </SimpleGrid>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
