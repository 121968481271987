import { Flex, HStack, Select, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ExportJsonCsv } from "react-export-json-csv";

export const Topbar = ({
  setLoadFigures,
  setRefresh,
  name,
  setDateRange,
  tests,
  setTestTimePeriod,
  testTimePeriod,
}) => {
  const headers = [
    {
      key: "TestName",
      name: "Test Name",
    },
    {
      key: "TotalTestTaken",
      name: "Total Test Taken",
    },
    {
      key: "TotalPatientsThatTookTest",
      name: "Total Patients That Took Test",
    },
    {
      key: "TotalTestResult",
      name: "Total Test Result",
    },
    {
      key: "SessionBookedAfterTest",
      name: "Session Booked After Test",
    },
    {
      key: "AboveAverage",
      name: "Above Average",
    },
    {
      key: "BelowAverage",
      name: "Below Average",
    },
  ];

  const data = [
    {
      TestName: name,
      TotalTestTaken: tests?.totalTestTaken || "--",
      TotalPatientsThatTookTest: tests?.totalPatientThatTookTest || "--",
      TotalTestResult: tests?.totalPatientThatTookTest || "--",
      SessionBookedAfterTest: tests?.sessionBookedAfterTest || "--",
      AboveAverage: tests?.aboveAveragePercent ? tests?.aboveAveragePercent + "%" : "--",
      BelowAverage: tests?.belowAveragePercent ? tests?.belowAveragePercent + "%" : "--",
    },
  ];

  return (
    <Flex
      justify={"space-between"}
      align="center"
      display={["block", "flex", "flex"]}
    >
      <Link to="/testReport">
        <Flex align={"center"} color="#636161">
          <BsArrowLeft />
          <Text fontWeight={"semibold"} fontSize="18px" pl="10px">
            Back
          </Text>
        </Flex>
      </Link>
      <HStack>
        <Text
          border={"1px solid #00B3B3"}
          color="#00B3B3"
          px="10px"
          py="5px"
          mr="30px"
        >
          <ExportJsonCsv headers={headers} items={data}>
            <Flex align={"center"} fontSize="15px" mx="15px">
              <AiOutlineDownload style={{ marginRight: "10px" }} />
              CSV
            </Flex>
          </ExportJsonCsv>
        </Text>
        <Select
          placeholder="Select option"
          color={"#636161"}
          bg="#FFFFFF"
          value={testTimePeriod}
          defaultValue="monthly"
          onChange={(e) =>
            // getReportRange(
            //   setDateRange,
            //   e.target.value,
            //   name,
            //   setRefresh,
            //   setLoadFigures
            // )
            setTestTimePeriod(e.target.value)
          }
        >
          <option value={"daily"}>Today</option>
          <option value={"monthly"}>Monthly</option>
          <option value={"yearly"}>Yearly</option>
        </Select>
      </HStack>
    </Flex>
  );
};
