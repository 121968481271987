import { Box, Flex, GridItem, SimpleGrid } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { tableBodyData } from "../../doctors/components/tableBodyData";
import { Card } from "../../finance/components/Card";
import { TopAppointmentCard } from "./TopAppointmentCard";
import { TopAssessmentCard } from "./TopAssessmentCard";
import cardBg from "../images/cardBgs.svg";
import {
  getTotalPayouts,
} from "../../finance/services/getWithdrawals";
import { getAppointment } from "../services";

export const SecondRow = ({ monthlyPayout, tests, topAssessment }) => {
  const [payout, setPayout] = useState(0);
  const [todayAppointment, setTodayAppointment] = useState([]);
  const [totalPayoutFilter, setTotalPayoutFilter] = useState("all");

  const getAppointments = useCallback(() => {
    getAppointment()
      .then((response) => {
        setTodayAppointment([...response]);
      })
      .catch((e) => {
        return null;
      });
  }, []);

  useEffect(() => {
    getAppointments();

    getTotalPayouts(totalPayoutFilter)
      .then((response) => {
        setPayout(response);
      })
      .catch((e) => {
        return null;
      });
  }, [getAppointments, totalPayoutFilter]);

  return (
    <Box mb="40px">
      <SimpleGrid spacing={3} columns={{ base: 1, md: 2, xl: 3 }}>
        <GridItem colSpan={{ base: 1, md: 2, xl: 1 }}>
          <Flex width={["100%"]} justifyContent="space-between">
            <Box width={"100%"}>
              <TopAppointmentCard getData={todayAppointment || []} />
            </Box>
          </Flex>
        </GridItem>

        <GridItem colSpan={{ base: 1, md: 1, xl: 1 }}>
          <TopAssessmentCard
            topAssessment={topAssessment}
            getData={tableBodyData?.slice(0, 5)}
          />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1, xl: 1 }}>
          <Card
            setFilter={setTotalPayoutFilter}
            bgImg={cardBg}
            bg={"#5C2BA8"}
            payment={payout || 0}
            paymetCaption={"Total Payout"}
            tests={monthlyPayout || 0}
            display={"block"}
          />
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
