import { Box, Divider, Flex, HStack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { PaginatedTable } from "../../components/PaginatedTable";
import { Table1 } from "./components/Table1";
import { TakeTestModal } from "./components/TakeTestModal";
import { getVideo } from "./services";
import FullPageLoader from "../../components/fullPageLoader";

export const TestUpload = () => {
  const [getVideos, setVideo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getVideo(setVideo, setLoading);
  }, [refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box bg="#EFEDF3">
      <Divider orientation="horizontal" />
      <Flex justify="space-between" align="center">
        <HStack>
          <Text fontSize="5xl" fontWeight="extrabold" color="#9E8FB5">
            {getVideos?.video?.length}
          </Text>
          <Text fontSize="md" color="#BBBBBB" fontWeight="semibold">
            Videos
          </Text>
        </HStack>
        <TakeTestModal setRefresh={setRefresh} />
      </Flex>
      <Divider orientation="horizontal" />

      <PaginatedTable
        columns={["S/N", "Video title", "Date created", "Action"]}
        bg="#EFEDF3"
        data={<Table1 videos={getVideos?.video} setRefresh={setRefresh} />}
        length={getVideos?.video?.length}
        total={getVideos?.totalVideo}
        updateTable={(page) => getVideo(setVideo, setLoading, page)}
      />
    </Box>
  );
};
