import { Avatar, Box, Flex, Stack, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiTwotoneStar } from "react-icons/ai";

export const TopDoctorCard = ({ getData }) => {
  return (
    <Box bg="#F6F4F9" borderTopRadius={"20px"} height={"210px"}>
      <Text color="#686278" fontSize={"18px"} fontWeight={"semibold"}>
        Top Therapists
      </Text>
      {/* <Divider /> */}
      {getData?.length ? (
        getData?.map((data, i) => {
          return (
            <Flex
              key={i}
              justifyContent={"space-between"}
              my="10px"
              borderBottom={"1px solid #EFEDF3"}
              py="7px"
            >
              <Flex width="100%">
                <Avatar
                  size="sm"
                  src={data?.doctorDetails?.profilePicture}
                  name={data?.doctorDetails?.name}
                  alt=""
                />
                <Box w="100%" ml="20px" maxH={"400px"}>
                  <Tooltip label={data?.name}>
                    <Text
                      color="#686278"
                      fontSize={"14px"}
                      fontWeight="bold"
                      lineHeight={1.2}
                      isTruncated
                      maxWidth={"100%"}
                    >
                      {data?.doctorDetails?.name}
                    </Text>
                  </Tooltip>
                  <Stack
                    direction={"row"}
                    width="90px"
                    alignItems="center"
                    fontSize={".7em"}
                    spacing="5px"
                    color="#686278"
                  >
                    <Text>{data?.doctorDetails?.ratings}</Text>
                    <AiTwotoneStar color="#09B5A9" />
                    <span>Ratings</span>
                  </Stack>
                </Box>
              </Flex>
            </Flex>
          );
        })
      ) : (
        <Text my={3} color={"gray.500"} fontSize="0.8em">
          {" "}
          No top therapist at the moment{" "}
        </Text>
      )}
    </Box>
  );
};
