import { errorNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const initiateForgotPassword = async (
  payload,
  setLoading,
  setAuthenticateCode
) => {
  try {
    await http.post(AUTH_ROUTES.FORGOT_PASSWORD, payload);

    setAuthenticateCode(true);
  } catch (e) {
    setLoading(false);
    setAuthenticateCode(false);
    errorNotifier(e.response?.data?.data);
  }
};

export const authenticateCode = async (
  payload,
  setLoading,
  setAuthenticateCode
) => {
  try {
    await http.post(AUTH_ROUTES.RESET_PASSWORD, payload);

    setAuthenticateCode(true);
  } catch (e) {
    setLoading(false);
    setAuthenticateCode(false);
    errorNotifier(e.response?.data?.data);
  }
};
