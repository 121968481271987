// import { errorNotifier, successNotifier } from "../../../components/NotificationHandler";
import { DATA_ROWS } from "../../../app/constants";
import http, { AUTH_ROUTES } from "../../../services/api";
import { getFilterString } from "../../../services/mixin";

export const getDoctors = async (
  setDoctors,
  setDoctorsForMemory,
  setTotalDoctors,
  setLoading,
  skip = 0,
  limit = DATA_ROWS.LIMIT
) => {
  const page = limit * skip;
  const params = {
    skip: page,
    limit,
  };
  try {
    console.log("called");
    const result = await http.get(AUTH_ROUTES.GET_DOCTORS(params));
    const data = result?.data?.doctorDetails?.data?.doctors;
    setDoctors(data);
    setDoctorsForMemory(data);
    setTotalDoctors(result?.data?.doctorDetails?.data?.totalDoctors);
    setLoading(false);
    
  } catch (e) {
    setLoading(false);

    // errorNotifier(e.response?.data?.data);
  }
};

export const getAllDoctors = async (filter) => {
  let filterString = getFilterString(filter);
  const result = await http.get(AUTH_ROUTES.GET_ALL_DOCTORS(filterString));
  return result?.data?.doctorDetails?.data;
};
export const getTotalDoctors = async (filter) => {
  if (filter === "all") {
    const result = await http.get(AUTH_ROUTES.GET_TOTAL_DOCTORS);
    return result?.data?.doctorDetails?.data?.totalDoctors;
  } else if (filter === "monthly") {
    const result = await http.get(AUTH_ROUTES.GET_MONTHLY_TOTAL_DOCTORS);
    return result?.data?.monthlyUserRecord?.data?.[0]?.count;
  } else {
    return 0;
  }
};

export const searchDoctor = async (searchTerm, setDoctors) => {
  try {
    const { data } = await http.get(
      AUTH_ROUTES.ADMIN_SEARCH_DOCTOR(searchTerm)
    );
    setDoctors(data?.search?.data);
  } catch (err) {
    return null;
  }
};
