import React from "react";
import { Tr, Td, Avatar, Flex, AvatarGroup } from "@chakra-ui/react";
import dayjs from "dayjs";
import video from "../../../assets/icons/video.svg";
import call from "../../../assets/icons/call.svg";

export const Table = ({ getData }) => {
  return (
    <>
      {getData?.map((item, i) => {
        let chatType = item?.chatType;
        let uniqueChatType = [...new Set(chatType)];
        return (
          <Tr
            key={i}
            bg="#FFFFFF"
            color="#352848"
            fontSize="12px"
            borderBottom="10px solid #EFEDF3"
          >
            <Td>
              <Flex>
                <AvatarGroup size="md" max={2} ml="12px">
                  <Avatar
                    w="44px"
                    h="44px"
                    src={item?.doctorId?.profilePicture}
                    // name={item?.doctorId?.name}
                  />
                  <Avatar
                    w="44px"
                    h="44px"
                    src={item?.patientId?.image}
                    // name={`${item?.patientId?.firstName} ${item?.patientId?.lastName} `}
                  />
                </AvatarGroup>
              </Flex>
            </Td>
            <Td>{item?.doctorId?.name}</Td>
            <Td>{`${item?.patientId?.firstName} ${item?.patientId?.lastName} `}</Td>
            <Td>{dayjs(item?.timer).format("MMM D YYYY")}</Td>

            <Td>
              {/* {getTimeDifference(
                new Date(item?.timer),
                new Date(item?.endDate)
              )} */}
              30 minutes
            </Td>

            <Td>
              <Flex>
                {uniqueChatType?.map(
                  (type) =>
                    type === "video" && (
                      <img src={video} alt="" style={{ marginRight: "15px" }} />
                    )
                )}
                {uniqueChatType?.map(
                  (type) =>
                    type === "audio" && (
                      <img src={call} alt="" style={{ marginRight: "15px" }} />
                    )
                )}
              </Flex>
            </Td>
          </Tr>
        );
      })}
    </>
  );
};
