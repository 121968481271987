import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FullPageLoader from "../../components/fullPageLoader";
import { AllNotification } from "./components/AllNotification";
import { getNotifications } from "./services/getNotifications";

export const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getNotifications(setNotifications, setLoading);
  }, [refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      {notifications?.length > 0 ? (
        <>
          <AllNotification
            notifications={notifications}
            setRefresh={setRefresh}
          />
        </>
      ) : (
        <Flex
          align={"center"}
          justify="center"
          fontSize={"50px"}
          color="#686278"
        >
          No notification available
        </Flex>
      )}
    </Box>
  );
};
