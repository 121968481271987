import http, { AUTH_ROUTES } from "../../../services/api";

export const getTestReport = async (
  setTests,
  testId,
  timePeriod,
  setLoading
) => {
  try {
    const result = await http.get(
      AUTH_ROUTES.GET_TEST_REPORT(testId, timePeriod)
    );
    setTests(result?.data?.reportDetails?.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const getTestName = async (setTestName) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_TEST_NAME);
    setTestName(result?.data?.testReport);
  } catch (e) {
    return null;
  }
};

export const getReportRange = async (
  setDateRange,
  dateRange,
  test,
  setRefresh,
  setLoading
) => {
  setLoading(true);
  try {
    const result = await http.get(
      AUTH_ROUTES.GET_REPORT_DROPDOWN(dateRange, test)
    );
    setDateRange(result?.data?.reportDetails?.data);
    setRefresh(true);
    setLoading(false);
  } catch (e) {
    setDateRange([]);
    setRefresh(true);
    setLoading(false);
  }
};
