import React from "react";
import { Tr, Td } from "@chakra-ui/react";
// import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MenuBox } from "./Menu"

export const TableBody = ({ getData, setRefresh }) => {
  
  return (
    <>
      {/* {getData?.map((data) => {
        return ( */}
      {getData?.map((data, i) => {
       
        return (
          <Tr
            // key={nanoid()}
            key={i}
            bg="#fff"
            color="#565758"
            //borderBottom="20px solid #fff"
            //borderRadius="5px"
            //boxShadow="md"
            
            fontWeight="bold"
            borderBottom='15px solid #EFEDF3'>
          
            {/* <Td fontSize={["12px", "14px", "16px"]}>
              <Avatar name="Joshua Keju" />
            </Td> */}
            <Td py="25px !important" fontSize={["12px", "14px", "14px"]} color="#929292">

              {data?.firstName} {" "}{data?.lastName}
              {/* <Text fontSize="12px" fontWeight="normal" color="#BBBBBB">
                clay@gmail.com
              </Text> */}
            </Td>
            <Td color="#929292" fontSize={["12px", "14px", "14px"]}>
              {data?.phoneNumber}
            </Td>

            <Td color="#929292" py="10px !important" fontSize={["12px", "14px", "14px"]}>
              {data?.email}
            </Td>
            <Td py="10px !important" color="#929292">{data?.address}</Td>
            <Td py="10px !important" color="#929292">
              
                {data?.package}
            </Td>
            {/*<Td py="10px !important" color="#929292">
              <Text bg="#CAF0F8" borderRadius="25px" p="5px" textAlign={"center"} width="100%">
              {data?.status}
            </Text>
            </Td>*/}
            <Td py={"5px !important"}>
              <MenuBox data={data} setRefresh={setRefresh} />
              {/* <BiDotsHorizontalRounded size="26px" cursor={"pointer"} /> */}
            </Td>
          </Tr>
        );
      })}

      {/* );
      })} */}
    </>
  );
};
