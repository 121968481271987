import { Box, Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { ConsultationCard } from "./ConsultationCard";

export const TopAppointmentCard = ({ getData }) => {
  return (
    <Box
      width={"100%"}
      height={"307px"}
      maxHeight="307px"
      borderRadius={"10px"}
      p="15px"
      bg="#F6F4F9"
      overflowY={"scroll"}
    >
      <Text>Today's Appointments</Text>
      <Flex mt="20px" width={"100%"}>
        <Box width="30%">
          <Text fontSize={"60px"} fontWeight="bold" color="#403058">
            {getData?.length || 0}
          </Text>
        </Box>
        <Box width={"80%"}>
          {!getData.length ? (
            <Text my={3} color={"gray.500"} fontSize="0.8em">
              {" "}
              No appointment set for today{" "}
            </Text>
          ) : (
            getData?.map((data, i) => {
              return (
                <Box key={i} mb="10px">
                  <ConsultationCard
                    visitationType={"Consultation"}
                    //patientsCase={data?.testTaken}
                    date={dayjs(data?.startDate).format("hh:mm a")}
                    endTime={dayjs(data?.endDate).format("hh:mm a")}
                  />
                </Box>
              );
            })
          )}
        </Box>
      </Flex>
    </Box>
  );
};
