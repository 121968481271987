export const getComponentTitle = (pathname) => {
  switch (pathname) {
    case "/dashboard":
      return "Dashboard";

    case "/profile":
      return "Profile";
    case "/notifications":
      return "Notifications";
    case "/finance":
      return "Finance";
    case "/doctors":
      return "Therapists";
    case "/users":
      return "Users";
    case "/reviews":
      return "Reviews";
    case "/testReport":
      return "TestReport";
    case "/meetingSummary":
      return "Meeting Summary";
    case "/testUpload":
      return "Video Upload";
    case "/subscription":
      return "Subscription";
    default:
      return "Welcome";
  }
};
