import { Box, Flex, Select } from "@chakra-ui/react";
import React from "react";
import { SearchBar } from "../../../components/SearchBar";

export const RatingDropdown = ({ searchTerm, setSearchTerm, handleFilter }) => {
  return (
    <Flex justifyContent={"space-between"} w="100%">
      <Box>
        <Select
          bg="#fff"
          defaultValue={""}
          color="#5C2BA8"
          onChange={(e) => handleFilter(e)}
        >
          <option value={""}>All</option>
          <option value={5}>5 Stars</option>
          <option value={4}>4 Stars</option>
          <option value={3}>3 Stars</option>
          <option value={2}>2 Stars</option>
          <option value={1}>1 Star</option>
        </Select>
      </Box>
      <Box>
        <SearchBar
          setSearchTerm={setSearchTerm}
          inputValue={searchTerm}
          placeholder={"Search therapist's ratings"}
        />
      </Box>
    </Flex>
  );
};
