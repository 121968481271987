import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const addUser = async (payload, setLoading) => {
  try {
    // const {
    //   data: { result },
    // }
    await http.post(AUTH_ROUTES.ADD_USER, payload);

    successNotifier("User successfully added");
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.message);
  }
};
