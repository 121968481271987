import {
  Box,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ArcElement, Title, Tooltip, Chart } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { GoPrimitiveDot } from "react-icons/go";
Chart?.register(Tooltip, Title, ArcElement);

export const Chartcard = ({ tests, chartt }) => {
  const data = {
    labels: ["Red", "Blue", "Green"],
    datasets: [
      {
        data: [tests?.aboveAveragePercent, tests?.belowAveragePercent],
        backgroundColor: ["#47248E", "#EFDE6D"],
        cutout: 80,
      },
    ],
  };
  const plugins = [
    {
      beforeDraw: function (chart) {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 80).toFixed(2);
        ctx.font = fontSize + "em Poppins";
        const halfFontSize = (fontSize / 3).toFixed(2);
        ctx.textBaseline = "top";
        let textX = Math.round((width - ctx.measureText(chartt).width) / 2),
          textY = height / 2.7;
        ctx.fillText(chartt || 0, textX, textY);
        //ctx.fillText("Total test result", textX, textY + 50);
        ctx.restore();
        ctx.font = halfFontSize + "em Poppins";
        ctx.fillText("Total test result", textX + -45, textY + 45);
        ctx.save();
        ctx.save();
      },
    },
  ];

  return (
    <Box>
      <Heading
        fontFamily={"Gilroy-Bold"}
        color="#636161"
        fontSize={"25px"}
        fontWeight={"semibold"}
        py="30px"
      >
        Test Result
      </Heading>
      <Flex
        bg="#FFFFFF"
        py="30px"
        px="30px"
        rounded={"20px"}
        align="center"
        justify={"space-around"}
        display={["block", "block", "flex"]}
      >
        <Stack w="230px" cutout="30%">
          <Doughnut data={data} plugins={plugins} />
        </Stack>
        <Flex lineHeight={1.0} mt={"60px"}>
          <Stack mr={"100px"}>
            <VStack>
              <Text fontSize={["30px", "30px", "60px", "100px"]}>
                {tests?.aboveAveragePercent || 0}%
              </Text>
              <HStack>
                <GoPrimitiveDot color="#47248E" size="1.6rem" />
                <Text fontSize={["9px", "10px", "12px"]}>Above Average</Text>
              </HStack>
            </VStack>
          </Stack>
          <VStack>
            <Text mx="30px" fontSize={["30px", "30px", "60px", "100px"]}>
              {tests?.belowAveragePercent || 0}%
            </Text>
            <HStack>
              <GoPrimitiveDot color="#EFDE6D" size="1.6rem" />
              <Text fontSize={["9px", "10px", "12px"]}>Below Average</Text>
            </HStack>
          </VStack>
          {/*<VStack>
            <Text fontSize={["30px", "30px", "60px", "80px"]}>{tests?.abandonedTestPercent || 0}%</Text>
            <HStack>
              <GoPrimitiveDot color="#00B3B3" size="1.6rem" />
              <Text fontSize={["9px", "10px", "12px"]}>Abandoned the test</Text>
            </HStack>
  </VStack>*/}
        </Flex>
      </Flex>
    </Box>
  );
};
