import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Formcontrol } from "./Formcontrol";
import { AiOutlinePlus } from "react-icons/ai";
import { useState } from "react";
import { addUser } from "../service/addUsers";

export const AddUsersModal = ({ setRefresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const payload = {
      email,
      address,
      firstName,
      lastName,
      phoneNumber,
    };

    setLoading(true);

    addUser(payload, setLoading)
      .then(() => {
        setRefresh(true);
        setLoading(false);
        onClose();
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        leftIcon={<AiOutlinePlus />}
        bg="#403058"
        color="#fff"
        borderRadius={"5px"}
        _hover={{ bg: "#9E8FB5" }}
        onClick={onOpen}
      >
        Add User
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#403058">Add User</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <Flex
              direction={["column", "column", "row"]}
              width={"100%"}
              justifyContent="space-between"
              mb="20px"
            >
              <Box width={["100%", "100%", "48%"]}>
                <Formcontrol
                  placeholder={"Enter your first name"}
                  name={"First Name"}
                  type="text"
                  value={firstName}
                  setValue={setFirstName}
                />
              </Box>
              <Box width={["100%", "100%", "48%"]}>
                <Formcontrol
                  placeholder={"Enter your last name"}
                  name={"Last Name"}
                  type="text"
                  value={lastName}
                  setValue={setLastName}
                />
              </Box>
            </Flex>
            <Flex
              direction={["column", "column", "row"]}
              width={"100%"}
              justifyContent="space-between"
            >
              <Box width={["100%", "100%", "48%"]}>
                <Formcontrol
                  name={"Phone Number"}
                  placeholder={"Enter your phone number"}
                  value={phoneNumber}
                  setValue={setPhoneNumber}
                />
              </Box>
              <Box width={["100%", "100%", "48%"]}>
                <Formcontrol
                  placeholder={"Enter your email"}
                  name="Email"
                  value={email}
                  setValue={setEmail}
                />
              </Box>
            </Flex>
            <Flex
              width="100%"
              justifyContent={"space-between"}
              alignItems="center"
              my="20px"
            >
              <Box width={["100%", "100%", "48%"]} mb="33px">
                <Formcontrol
                  placeholder={"Enter your address"}
                  name="Address"
                  value={address}
                  setValue={setAddress}
                />
              </Box>
              <Box width={["100%", "100%", "48%"]}>
                <Button
                  bg="#5C2BA8"
                  color="#fff"
                  _hover={{ bg: "#5C2BA8" }}
                  width="100%"
                  textAlign={"center"}
                  // mr={3}
                  isLoading={loading}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
