import { Box, Grid, GridItem } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import FullPageLoader from "../../components/fullPageLoader";
import { getTestName } from "../general-report/services/getTestReport";
import { Boxe } from "./components/Boxe";

export const TestReport = () => {
  const [loading, setLoading] = useState(true);
  const [testNamee, setTestNamee] = useState([]);

  useEffect(() => {
    getTestName(setTestNamee).then(() => setLoading(false));
  }, []);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Grid
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(2, 2fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
        ]}
        gap="1em"
      >
        {testNamee?.data?.map((item) => {
          return (
            <GridItem key={item}>
              <Boxe test={item} />
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};
