import http, { AUTH_ROUTES } from "../../../services/api";

export const getNotifications = async (setNotifications, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_NOTIFICATIONS);
    setNotifications(result?.data?.notifications?.data?.notifications);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};
