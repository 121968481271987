import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FullPageLoader from "../../components/fullPageLoader";
import { getSubscriptionList } from "./services";
import { updateSubscription } from "./services/updateSubscription.js";

export const Subscription = () => {
  //const [subscriptions, setSubscriptions] = useState([]);
  const tabName = ["Basic", "Gold", "Premium"];
  const [basicInfo, setBasicInfo] = useState({});
  const [goldInfo, setGoldInfo] = useState({});
  const [premiumInfo, setPremiumInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [update, setUpdate] = useState({});

  useEffect(() => {
    setLoading(true);
    getSubscriptionList()
      .then((data) => {
        let sorted = data?.subscriptionSettings?.sort(
          (a, b) => a?.amount - b?.amount
        );
        // setSubscriptions([...sorted]);

        let basic = sorted?.find((data) => data?.subscriptionName === "Basic");
        let gold = sorted?.find((data) => data?.subscriptionName === "Gold");
        let premium = sorted?.find(
          (data) => data?.subscriptionName === "Premium"
        );

        setBasicInfo(basic);
        setGoldInfo(gold);
        setPremiumInfo(premium);
      })
      .catch((e) => {
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [update]);

  const handleSubscription = (data) => {
    setLoadingUpdate(true);
    const payload = { amount: data?.amount };
    updateSubscription(payload, data?._id, setLoadingUpdate).then(() =>
      setUpdate({})
    );
  };

  return (
    <Box bg="#F6F4F9" align="center" p="50px" rounded="lg" mx="20px">
      {loading ? (
        <FullPageLoader />
      ) : (
        <Tabs
          isFitted
          variant="soft-rounded"
          colorScheme="purple"
          size="lg"
          w="100%"
          maxW={"500px"}
          pl="5px"
          color="#352848"
        >
          <TabList bg="#FFFFFF" rounded="full">
            {tabName.map((subscription, index) => (
              <Tab key={index} _selected={{ color: "white", bg: "#9E8FB5" }}>
                {" "}
                {subscription}{" "}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex
                direction={"column"}
                w="100%"
                alignItems="center"
                mt="100px"
              >
                <Stack
                  width={"100%"}
                  direction={{ base: "column", md: "row" }}
                  align="flex-start"
                >
                  <Box>
                    <Text color="#352848">Price</Text>
                    <Input
                      //defaultValue={subscription?.amount}
                      type={"number"}
                      name="amount"
                      placeholder="NGN 3000"
                      bg="#FFFFFF"
                      size="lg"
                      border="1px #EEE4FE"
                      value={basicInfo?.amount}
                      onChange={(e) =>
                        setBasicInfo((prev) => ({
                          ...prev,
                          amount: e.target.value,
                        }))
                      }
                    />
                  </Box>
                  <Box align="flex-start">
                    <Text color="#352848">Sessions</Text>
                    <Input
                      type={"number"}
                      name="sessiont"
                      defaultValue={basicInfo?.session}
                      placeholder="3 Sessions"
                      bg="#FFFFFF"
                      size="lg"
                      border="1px #EEE4FE"
                      onChange={(e) =>
                        setBasicInfo((prev) => ({
                          ...prev,
                          session: e.target.value,
                        }))
                      }
                    />
                  </Box>
                </Stack>
                {/* <Flex mt="22px" fontSize={"18px"}>
                  Mark features that should included in this package
                </Flex>
                <Flex mt="12px">
                  <Box>
                    <Checkbox
                      // isChecked={checkBox === "chat"}
                      // onChange={() => setCheckBox("chat")}
                      value="chat"
                      color={"#352848"}
                      fontSize={"15px"}
                      fontWeight={300}
                    >
                      Chat
                    </Checkbox>{" "}
                  </Box>
                  <Box ml="22px">
                    {" "}
                    <Checkbox
                      // isChecked={checkBox === "Voicecall"}
                      // onChange={() => setCheckBox("Voicecall")}
                      fontSize={"5px"}
                      value="Voicecall"
                      color={"#352848"}
                      fontWeight={300}
                    >
                      Voice call
                    </Checkbox>
                  </Box>
                  <Box ml="22px">
                    {" "}
                    <Checkbox
                      // isChecked={checkBox === "Videocall"}
                      // onChange={() => setCheckBox("Videocall")}
                      value="Videocall"
                      color={"#352848"}
                      fontWeight={300}
                    >
                      Video call
                    </Checkbox>
                  </Box>
                </Flex> */}
                <Button
                  type="submit"
                  bg="#403058"
                  color="#EEE4FE"
                  width={"100%"}
                  maxW="300px"
                  mt="50px"
                  size="lg"
                  _hover={{ background: "#403058" }}
                  _focus={{ boxShadow: "none" }}
                  isLoading={loadingUpdate}
                  onClick={() => handleSubscription(basicInfo)}
                >
                  Update
                </Button>
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex
                direction={"column"}
                w="100%"
                alignItems="center"
                mt="100px"
              >
                <Stack
                  width={"100%"}
                  direction={{ base: "column", md: "row" }}
                  align="flex-start"
                >
                  <Box>
                    <Text color="#352848">Price</Text>
                    <Input
                      //defaultValue={subscription?.amount}
                      name="amount"
                      // placeholder="NGN 3000"
                      bg="#FFFFFF"
                      size="lg"
                      border="1px #EEE4FE"
                      value={goldInfo?.amount}
                      onChange={(e) =>
                        setGoldInfo((prev) => ({
                          ...prev,
                          amount: e.target.value,
                        }))
                      }
                    />
                  </Box>
                  <Box align="flex-start">
                    <Text color="#352848">Sessions</Text>
                    <Input
                      name="sessiont"
                      defaultValue={goldInfo?.session}
                      // placeholder="3 Sessions"
                      bg="#FFFFFF"
                      size="lg"
                      border="1px #EEE4FE"
                      onChange={(e) =>
                        setGoldInfo((prev) => ({
                          ...prev,
                          session: e.target.value,
                        }))
                      }
                    />
                  </Box>
                </Stack>
                {/* <Flex mt="22px" fontSize={"18px"}>
                  Mark features that should included in this package
                </Flex>
                <Flex mt="12px">
                  <Box>
                    <Checkbox
                      // isChecked={checkBox === "chat"}
                      // onChange={() => setCheckBox("chat")}
                      value="chat"
                      color={"#352848"}
                      fontSize={"15px"}
                      fontWeight={300}
                    >
                      Chat
                    </Checkbox>{" "}
                  </Box>
                  <Box ml="22px">
                    {" "}
                    <Checkbox
                      // isChecked={checkBox === "Voicecall"}
                      // onChange={() => setCheckBox("Voicecall")}
                      fontSize={"5px"}
                      value="Voicecall"
                      color={"#352848"}
                      fontWeight={300}
                    >
                      Voice call
                    </Checkbox>
                  </Box>
                  <Box ml="22px">
                    {" "}
                    <Checkbox
                      // isChecked={checkBox === "Videocall"}
                      // onChange={() => setCheckBox("Videocall")}
                      value="Videocall"
                      color={"#352848"}
                      fontWeight={300}
                    >
                      Video call
                    </Checkbox>
                  </Box>
                </Flex> */}
                <Button
                  type="submit"
                  bg="#403058"
                  color="#EEE4FE"
                  width={"100%"}
                  maxW="300px"
                  mt="50px"
                  size="lg"
                  _hover={{ background: "#403058" }}
                  _focus={{ boxShadow: "none" }}
                  isLoading={loadingUpdate}
                  onClick={() => handleSubscription(goldInfo)}
                >
                  Update
                </Button>
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex
                direction={"column"}
                w="100%"
                alignItems="center"
                mt="100px"
              >
                <Stack
                  width={"100%"}
                  direction={{ base: "column", md: "row" }}
                  align="flex-start"
                >
                  <Box>
                    <Text color="#352848">Price</Text>
                    <Input
                      //defaultValue={subscription?.amount}
                      name="amount"
                      // placeholder="NGN 3000"
                      bg="#FFFFFF"
                      size="lg"
                      border="1px #EEE4FE"
                      value={premiumInfo?.amount}
                      onChange={(e) =>
                        setPremiumInfo((prev) => ({
                          ...prev,
                          amount: e.target.value,
                        }))
                      }
                    />
                  </Box>
                  <Box align="flex-start">
                    <Text color="#352848">Sessions</Text>
                    <Input
                      name="sessiont"
                      defaultValue={premiumInfo?.session}
                      // placeholder="3 Sessions"
                      bg="#FFFFFF"
                      size="lg"
                      border="1px #EEE4FE"
                      onChange={(e) =>
                        setPremiumInfo((prev) => ({
                          ...prev,
                          session: e.target.value,
                        }))
                      }
                    />
                  </Box>
                </Stack>
                {/* <Flex mt="22px" fontSize={"18px"}>
                  Mark features that should included in this package
                </Flex>
                <Flex mt="12px">
                  <Box>
                    <Checkbox
                      // isChecked={checkBox === "chat"}
                      // onChange={() => setCheckBox("chat")}
                      value="chat"
                      color={"#352848"}
                      fontSize={"15px"}
                      fontWeight={300}
                    >
                      Chat
                    </Checkbox>{" "}
                  </Box>
                  <Box ml="22px">
                    {" "}
                    <Checkbox
                      // isChecked={checkBox === "Voicecall"}
                      // onChange={() => setCheckBox("Voicecall")}
                      fontSize={"5px"}
                      value="Voicecall"
                      color={"#352848"}
                      fontWeight={300}
                    >
                      Voice call
                    </Checkbox>
                  </Box>
                  <Box ml="22px">
                    {" "}
                    <Checkbox
                      // isChecked={checkBox === "Videocall"}
                      // onChange={() => setCheckBox("Videocall")}
                      value="Videocall"
                      color={"#352848"}
                      fontWeight={300}
                    >
                      Video call
                    </Checkbox>
                  </Box>
                </Flex> */}
                <Button
                  bg="#403058"
                  color="#EEE4FE"
                  width={"100%"}
                  maxW="300px"
                  mt="50px"
                  size="lg"
                  _hover={{ background: "#403058" }}
                  _focus={{ boxShadow: "none" }}
                  isLoading={loadingUpdate}
                  onClick={() => handleSubscription(premiumInfo)}
                >
                  Update
                </Button>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Box>
  );
};
