import React from "react";
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Box,
} from "@chakra-ui/react";

export default function CautionAlertDialog({
  onContinue,
  button,
  cautionTitle,
  agree,
  disagree,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const BTN = { _focus: { boxShadow: "none" } };

  const handleClick = () => {
    onClose();
    onContinue(true);
  };

  return (
    <>
      <Box onClick={onOpen}>{button}</Box>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{cautionTitle}</AlertDialogHeader>

          <AlertDialogFooter>
            <Button
              {...BTN}
              _hover={{ background: "#403058" }}
              _focus={{ background: "#403058" }}
              bg="#403058"
              color="#fff"
              ref={cancelRef}
              onClick={onClose}
            >
              {disagree || "No"}
            </Button>
            <Button {...BTN} onClick={handleClick} ml={3}>
              {agree || "Yes"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
