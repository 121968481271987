import { Avatar, Box, Flex, Td, Text, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";

export const PatientReview = ({ getData }) => {
  const [rateValue, setRateValue] = useState(0);

  const ratingChanged = (e) => {
    setRateValue(e);
  };
  return (
    <>
      {getData?.map((data, i) => {
        return (
          <Tr
            key={data._id}
            bg="#F6F4F9"
            color="#000000"
            borderBottom="5px solid #f2f2f2"
            mb="10px"
            borderRadius="10px"
            // boxShadow="md"
            fontWeight="normal"
          >
            <Td>{i + 1}</Td>
            <Td width={"20%"} p="30px !important">
              {data?.userId?.firstName} {data?.userId?.lastName}
            </Td>

            <Td width="20%">
              <Flex alignItems={"center"}>
                <Avatar
                  size={"sm"}
                  name={data?.doctorId?.name}
                  src={data?.doctorId?.profilePicture}
                  mr="5px"
                />
                <Box>
                  <Text>{data?.doctorId?.name}</Text>
                  {/* <Text fontSize={"12px"} color="#BBBBBB">
                      {data?.email}
                    </Text> */}
                </Box>
              </Flex>
            </Td>
            <Td width="40%" p="30px !important">
              <ReactStars
                count={5}
                value={data?.ratings}
                edit={false}
                onChange={ratingChanged}
                size={30}
                activeColor={"#3A3A3A"}
                color="#adadad"
              />
              <Text mt="15px">{data?.comment}</Text>
            </Td>
            <Td width="20%" p="30px !important">
              {dayjs(data?.createdAt)?.format("DD MMMM YYYY")}
            </Td>
            {/* <Td>
              <MenuLItems name={data?.name} dateCreated={data?.dateCreated} />
            </Td> */}
          </Tr>
        );
      })}
    </>
  );
};
