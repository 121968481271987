// import { errorNotifier, successNotifier } from "../../../components/NotificationHandler";
import { DATA_ROWS } from "../../../app/constants";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getUsers = async (setUsers, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_USERS);
    setUsers(result?.data?.userDetails?.data?.users);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const getTotalUsers = async (filter) => {
  if (filter === "all") {
    const result = await http.get(AUTH_ROUTES.GET_TOTAL_USERS);
    return result?.data?.userDetails?.data?.totalUsers;
  } else if (filter === "monthly") {
    const result = await http.get(AUTH_ROUTES.GET_MONTHLY_TOTAL_USERS);
    return result?.data?.monthlyUserRecord?.data?.[0]?.count;
  } else {
    return 0;
  }
};

export const getAllUsers = async (skip = 0, limit = DATA_ROWS.LIMIT) => {
  const page = limit * skip;
  const params = {
    skip: page,
    limit,
  };

  const result = await http.get(AUTH_ROUTES.GET_ALL_USERS(params));
  return result?.data?.userDetails?.data;
};

export const searchUser = async (searchTerm, setFilteredUsers) => {
  try {
    const { data } = await http.get(AUTH_ROUTES.ADMIN_SEARCH_USER(searchTerm));
    setFilteredUsers(data?.search?.data);
  } catch (err) {
    return null;
  }
};
