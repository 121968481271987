import { Avatar, Box, Divider, Flex, Text, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiTwotoneStar } from "react-icons/ai";
// import { PayoutModal } from './PayoutModal'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { updatePayout } from "../services/getWithdrawals";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const PayoutRequests = ({ getData, setRefresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [modalData, setModalData] = useState({});
  const { amount, doctorId, _id } = modalData || {};
  const { name, phoneNumber, email } = doctorId || {};
  const [loading, setLoading] = useState(false);

  const handleSubmit = (payoutId) => {
    setLoading(true);
    updatePayout(payoutId)
      .then(() => {
        setLoading(false);
        successNotifier("Approved successfully");
        setRefresh({});
        onClose();
      })
      .catch((e) => {
        setLoading(false);
        errorNotifier(e?.response?.data?.message);
      });
  };

  return (
    <Box p="30px 20px" bg="#F6F4F9" borderTopRadius={"20px"}>
      <Text color="#686278" fontSize={"18px"} fontWeight={"semibold"}>
        Payout Requests
      </Text>
      <Divider />
      {getData?.map((data, i) => {
        return data ? (
          <Flex
            cursor="pointer"
            onClick={() => {
              setModalData(data);
              onOpen();
            }}
            key={i}
            justifyContent={"space-between"}
            my="10px"
            borderBottom={"1px solid #EFEDF3"}
            py="7px"
          >
            <Flex width="70%">
              <Avatar
                size="sm"
                src={data?.doctorId?.profilePicture}
                name={data?.name}
                alt=""
              />
              <Box ml="20px">
                <Tooltip label={data?.doctorId?.name}>
                  <Text
                    color="#686278"
                    fontSize={"12px"}
                    fontWeight="normal"
                    lineHeight={1.2}
                    maxWidth="100px"
                    isTruncated
                  >
                    Dr {data?.doctorId?.name || "N/A"}
                  </Text>
                </Tooltip>
                <Flex
                  width="90px"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Text fontSize={"12px"}>{data?.doctorId?.ratings}</Text>
                  <AiTwotoneStar color="#09B5A9" />
                  <span>Ratings</span>
                </Flex>
              </Box>
            </Flex>
            <Tooltip label={"amount requested"}>
              <Box>
                <Text color="#8C78AB">
                  {`\u20A6${data?.amount?.toLocaleString()}` || "N/A"}
                </Text>
                <Text color="#686278" fontSize={"6px"}>
                  Amount Requested
                </Text>
              </Box>
            </Tooltip>
          </Flex>
        ) : (
          <Flex
            align={"center"}
            justify="center"
            fontSize={"30px"}
            color="#686278"
          >
            No Payout Request
          </Flex>
        );
      })}

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Flex justifyContent={"space-between"} px={"46px"} py={"26px"}>
            <Text color={"#403058"} fontSize={"24px"} fontWeight={800}>
              Payout Request
            </Text>
            <Text fontSize={"14px"} color={"#200960"}>
              Balance:
              <Text as="span" color={"#3D348B"} fontSize={"25px"}>
                {" "}
                {`\u20A6${doctorId?.wallet?.balance?.toLocaleString()}`}
              </Text>{" "}
            </Text>
          </Flex>
          <Box ml="20px" w={"400px"} h="2px" bg="#E3E3E3"></Box>

          <ModalBody px={"46px"}>
            <Flex justifyContent={"space-between"}>
              <Flex direction={"column"}>
                <Text fontSize={"14px"} color={"#686278"}>
                  Name
                </Text>
                <Text fontSize={"23x"} color={"#575757"} fontWeight={600}>
                  <Text>{name}</Text>
                </Text>
              </Flex>
              <Flex direction={"column"}>
                <Text fontSize={"14px"} mr={4} color={"#686278"}>
                  Amount requested
                </Text>
                <Text fontSize={"23x"} color={"#575757"} fontWeight={600}>
                  <Text>{`\u20A6${amount?.toLocaleString()}`}</Text>
                </Text>
              </Flex>
            </Flex>
            <br />
            <Flex justifyContent={"space-between"}>
              <Flex direction={"column"}>
                <Text fontSize={"14px"} color={"#686278"}>
                  Phone Number
                </Text>
                <Text fontSize={"23x"} color={"#575757"} fontWeight={600}>
                  <Text>{phoneNumber}</Text>
                </Text>
              </Flex>
              <Flex direction={"column"}>
                <Text fontSize={"14px"} color={"#686278"}>
                  Email Address
                </Text>
                <Text fontSize={"23x"} color={"#575757"} fontWeight={600}>
                  {email}
                </Text>
              </Flex>
            </Flex>
            <br />

            <Flex justifyContent={"space-between"}>
              <Flex direction={"column"}>
                {/* <Text fontSize={"14px"} color={"#686278"}>
                  Account Number
                </Text> */}
                {/* <Text fontSize={"23x"} color={"#575757"} fontWeight={600}>
                  0085163568
                </Text> */}
              </Flex>
              <Flex direction={"column"}>
                {/* <Text fontSize={"14px"} mr={14} color={"#686278"}>
                  Bank Name
                </Text> */}
                {/* <Text fontSize={"23x"} color={"#575757"} fontWeight={600}>
                  Stanbic ibtc
                </Text> */}
              </Flex>
            </Flex>

            {/* <Lorem count={2} /> */}
          </ModalBody>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <ModalFooter>
              <Button
                _focus={{ boxShadow: "none" }}
                colorScheme="#5C2BA8"
                variant="outline"
                mr={3}
                onClick={onClose}
                h={"52px"}
                w={"180px"}
                borderRadius={"5px"}
              >
                Cancel
              </Button>
              <Button
                bg="#5C2BA8"
                _hover={{ background: "#5C2BA8" }}
                _focus={{ boxShadow: "none" }}
                borderRadius={"5px"}
                color="#FFFFFF"
                //onClick={onClose}
                w={"180px"}
                h={"52px"}
                isLoading={loading}
                onClick={() => handleSubmit(_id)}
              >
                Approve
              </Button>
            </ModalFooter>
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
};
