import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
//import { ImDownload } from "react-icons/im";
import { HiOutlineDownload } from "react-icons/hi";
import { AddUsersModal } from "./AddUsersModal";
import { ExportJsonCsv } from "react-export-json-csv";
import { SearchBar } from "../../../components/SearchBar";

export const TestRow = ({
  dataRow,
  total,
  setRefresh,
  searchTerm,
  setSearchTerm,
}) => {
  const headers = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "phoneNumber",
      name: "Phone Number",
    },
    {
      key: "email",
      name: "Email",
    },
    {
      key: "address",
      name: "Address",
    },
    {
      key: "package",
      name: "Package",
    },
  ];

  const data = dataRow?.users?.map((item) => {
    return {
      name: item?.firstName + " " + item?.lastName,
      phoneNumber: String(item?.phoneNumber),
      email: item?.email,
      address: item?.address,
      package: item?.package,
    };
  });

  return (
    <Box bg="inherit">
      <Divider />
      <Flex
        direction={["column", "column", "row"]}
        justifyContent={"space-between"}
        alignItems="center"
        my="20px"
        width="100%"
      >
        <Flex
          width={["100%", "100%", "40%"]}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Flex
            direction={["column", "row"]}
            width={["30%", "30%", "40%"]}
            justifyContent={["flex-start", "space-between"]}
            alignItems="center"
            color="#BBBBBB"
          >
            <Text color="#9E8FB5" fontSize={"36px"} fontWeight={"bold"}>
              {total || 0}
            </Text>
            <Text>Patients</Text>
            <Divider orientation="vertical" />
          </Flex>

          <SearchBar
            placeholder={"Search users"}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Flex>
        <Flex justifyContent={"space-between"} width={["100%", "100%", "23%"]}>
          <ExportJsonCsv headers={headers} items={data}>
            <Flex
              justifyContent={"center"}
              alignItems="center"
              bg="#fff"
              p={["3px 8px", "5px 15px"]}
              mr="2px"
              cursor={"pointer"}
            >
              <HiOutlineDownload />
              <Text ml="8px" color="#403058">
                CSV
              </Text>
            </Flex>
          </ExportJsonCsv>
          {/*<Flex
            justifyContent={"center"}
            alignItems="center"
            bg="#fff"
            p="5px 15px"
            mr="4px"
            cursor={"pointer"}
          >
            <ImDownload />
            <Text ml="8px" color="#403058">
              PDF
        </Text>
          </Flex>*/}

          <AddUsersModal setRefresh={setRefresh} />
        </Flex>
      </Flex>
      <Divider />
    </Box>
  );
};
