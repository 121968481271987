import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Card } from "./Card";

import cardBg from "../images/bgImage.svg";
import { useState } from "react";
import { useEffect } from "react";
import { getTotalPayouts } from "../services/getWithdrawals";
import { getMonthlyPayout } from "../../dashboard/services/getMonthlyTest";

export const PayInPayOutCards = ({
  //payout,
  monthly,
  userPayIn,
  payouts,
  setFilter,
  //setTotalPayoutFilter,
  setTotalUserPayinFilter,
  //monthlyPayout,
}) => {
  const [monthlyPayout, setMonthlyPayout] = useState([]);
  const [payout, setPayout] = useState(0);
  const [totalPayoutFilter, setTotalPayoutFilter] = useState("all");

  useEffect(() => {
    getTotalPayouts(totalPayoutFilter)
      .then((response) => {
        setPayout(response);
      })
      .catch((e) => {
        getMonthlyPayout(setMonthlyPayout);
      });
  }, [totalPayoutFilter]);

  return (
    <Flex
      direction={["column", "column", "row"]}
      width={"100%"}
      justifyContent="space-between"
      my={["20px", "20px", 0]}
    >
      <Box width={["100%", "100%", "49%"]} mb={["20px", "20px", 0]}>
        <Card
          setFilter={setTotalPayoutFilter}
          tests={monthlyPayout || 0}
          bgImg={cardBg}
          bgPosition={"right"}
          bg={"#5C2BA8"}
          paymetCaption={"Total Payout"}
          payment={payout || 0}

          // monthly={monthly || 0}
          //payment={payouts}
          //setFilter={setTotalPayoutsFilter}
        />
      </Box>
      <Box width={["100%", "100%", "49%"]}>
        {" "}
        <Card
          bg={"#F6F4F9"}
          color={"#686278"}
          setFilter={setTotalUserPayinFilter}
          paymetCaption={"Total Paid in"}
          // payment={
          //   userPayIn?.totalPayin?.data?.totalPay_in?.[0]?.TotalAmount || 0
          // }
          // monthly={
          //   userPayIn?.totalPayin?.data?.monthlyPayInRecord?.[0]?.TotalAmount ||
          //   0
          // }
          payment={userPayIn}
        />
      </Box>
    </Flex>
  );
};
