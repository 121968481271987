import { PROTECTED_PATHS } from "../../../../app/constants";
// import { ImStack } from "react-icons/im";
import { MdOutlineDashboard } from "react-icons/md";
import { IoPersonOutline } from "react-icons/io5";
import { BsBell } from "react-icons/bs";
import { RiWaterFlashLine } from "react-icons/ri";
import { CgArrowUpR } from "react-icons/cg";
import { GoNote } from "react-icons/go";
import { AiOutlineStar, AiOutlineMedicineBox } from "react-icons/ai";

const {
  DASHBOARD,
  TESTREPORT,
  DOCTORS,
  USERS,
  FINANCE,
  NOTIFICATIONS,
  TESTUPLOAD,
  REVIEWS,
  SUBSCRIPTION,
  MEETINGS,
} = PROTECTED_PATHS;

export const ADMIN_NAV_ITEMS = [
  {
    title: "Dashboard",
    to: DASHBOARD,
    icon: MdOutlineDashboard,
  },
  {
    title: "Test Report",
    to: TESTREPORT,
    icon: GoNote,
  },
  {
    title: "Users",
    to: USERS,
    icon: IoPersonOutline,
  },
  {
    title: "Therapists",
    to: DOCTORS,
    icon: AiOutlineMedicineBox,
  },
  {
    title: "Subscription",
    to: SUBSCRIPTION,
    icon: AiOutlineStar,
  },
  {
    title: "Finance",
    to: FINANCE,
    icon: RiWaterFlashLine,
  },
  // {
  //   title: "Video Upload",
  //   to: VIDEOUPLOAD,
  //   icon: CgArrowUpR,
  // },

  {
    title: "Meeting Summary",
    to: MEETINGS,
    icon: GoNote,
  },
  {
    title: "Video Upload",
    to: TESTUPLOAD,
    icon: CgArrowUpR,
  },

  {
    title: "Reviews",
    to: REVIEWS,
    icon: AiOutlineStar,
  },
  {
    title: "Notifications",
    to: NOTIFICATIONS,
    icon: BsBell,
  },
];
