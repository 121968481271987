import http, { AUTH_ROUTES } from "../../../services/api";

export const getPayouts = async () => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_PAYOUTS);
    return result?.data?.getAllWithdrawal?.data;
  } catch (e) {
    return null;
  }
};

export const getTotalPayouts = async (filter) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_PAYOUTS);
    if (filter === "all") {
      return result?.data?.getAllWithdrawal?.data?.totalPayout?.[0]
        ?.TotalAmount;
    } else if (filter === "monthly") {
      return result?.data?.getAllWithdrawal?.data?.monthlyRecord?.[0]
        ?.TotalAmount;
    }
  } catch (e) {
    return null;
  }
};

export const getWithdrawals = async (setWithdrawals, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_WITHDRAWALS);
    setWithdrawals(result?.data?.payoutRequest?.data?.requests);
    setLoading(false);
     console.log(result?.data?.payoutRequest?.data?.requests);
  } catch (e) {
    setLoading(false);
  }
};

export const updatePayout = async (payoutId) => {
  const result = await http.put(AUTH_ROUTES.UPDATE_PAYOUT_STATUS(payoutId));
  return result;
};
