import React, { useCallback, useEffect, useState } from "react";
import { Box, Stack } from "@chakra-ui/react";
import { FirstRow } from "./components/FirstRow";
import { SecondRow } from "./components/SecondRow";
//import { UserProfileContext } from "../../context/UserContext";
import { getWithdrawals } from "../finance/services/getWithdrawals";
import FullPageLoader from "../../components/fullPageLoader";
import { getTestTaken } from "../test-upload/services";
import { getMonthlyDoctors } from "./services/getMonthlyDoctor";
import {
  getMonthlyTest,
  getMonthlyUser,
  getMonthlyPayout,
  getConsultations,
} from "./services/getMonthlyTest";
import { getTopAssessment } from "./services";

export const Dashboard = () => {
  //const userProfile = useContext(UserProfileContext);
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [testTaken, setTestTaken] = useState(0);
  const [monthly, setMonthly] = useState([]);
  const [test, setTest] = useState("");
  const [user, setUser] = useState("");
  const [monthlyPayout, setMonthlyPayout] = useState([]);
  const [totalConsultations, setTotalConsultations] = useState(0);
  const [topAssessment, setTopAssessment] = useState([]);
  const [refresh, setRefresh] = useState({});

  const [testTakenFilter, setTestTakenFilter] = useState("all");
  const [consultationFilter, setConsultationFilter] = useState("all");

  const getTotalTestTaken = useCallback(() => {}, []);

  useEffect(() => {
    getTestTaken(testTakenFilter)
      .then((response) => {
        setTestTaken(response);
      })
      .catch((e) => {
        return null;
      });
    getWithdrawals(setWithdrawals, setLoading);
    getTotalTestTaken();
    getMonthlyDoctors(setMonthly);
    getMonthlyTest(setTest);
    getMonthlyUser(setUser);
    getMonthlyPayout(setMonthlyPayout);
    getConsultations(consultationFilter, setTotalConsultations);
    getTopAssessment(setTopAssessment);
  }, [testTakenFilter, consultationFilter, refresh, getTotalTestTaken]);

  useEffect(() => {
    const tests = [];
    testTaken?.test?.map((test) => tests.push(test.testName));
    //const [object, setObject] = useState([])
    const count = {};
    for (const element of tests) {
      if (count[element]) {
        count[element] += 1;
      } else {
        count[element] = 1;
      }
    }
  }, [testTaken]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width="100%">
      <Stack spacing={3} width="100%">
        <Box mb="10px">
          <FirstRow
            setTestTakenFilter={setTestTakenFilter}
            setConsultationFilter={setConsultationFilter}
            setRefresh={setRefresh}
            refresh={refresh}
            getData={withdrawals?.filter(
              (withdrawal) => withdrawal?.status?.toLowerCase() === "pending"
            )}
            testTaken={testTaken}
            data={monthly}
            tests={test}
            setTest={setTest}
            users={user}
            totalConsultations={totalConsultations}
          />
        </Box>
        <SecondRow
          monthlyPayout={monthlyPayout}
          topAssessment={topAssessment}
        />
      </Stack>
    </Box>
  );
};
