import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const deleteUser = async (userId) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_USER(userId));
    successNotifier("User Deleted Successfully");
  } catch (e) {
    errorNotifier(e.response?.data?.message);
  }
};
