import { Box, Text, Stack } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { LinkTo } from "../../nav/components/LinkTo";
import "./index.css";
import { ADMIN_NAV_ITEMS } from "./components/sidebar.constatnt";
import { BiLogOut } from "react-icons/bi";
import { useState } from "react";

const SideBar = ({ toggle }) => {
  const [activeIconId, setActiveIconId] = useState("");
  const handleLogout = () => {
    localStorage.removeItem("HBA#221#");
    window.location.href = "/";
  };

  return (
    <Box bg="#EFEDF3" mt={["110px"]}>
      <Box className="sidebar">
        <Box className="sidebar-inner">
          <Flex flex={1} flexDirection="column">
            {ADMIN_NAV_ITEMS.map(({ title, to, icon: Icon }, id) => (
              <LinkTo
                key={id}
                to={to}
                title={title}
                toggle={toggle}
                id={id}
                iconComponent={Icon}
                activeIconId={activeIconId}
                setActiveIconId={setActiveIconId}
              />
            ))}
            <Box className="link">
              <Stack
                mt="20px"
                cursor="pointer"
                onClick={handleLogout}
                direction="row"
                alignItems="center"
                color="#200960"
                _hover={{ color: "red" }}
              >
                <BiLogOut />
                <Text>Log out</Text>
              </Stack>
            </Box>
          </Flex>

          {/* <Box className="link">
            <Stack
              cursor="pointer"
              onClick={handleLogout}
              direction="row"
              alignItems="center"
              color="red"
            >
              <BiLogOut />
              <Text>Log out</Text>
            </Stack>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};
export default SideBar;
