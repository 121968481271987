import "./index.css";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
// import dayjs from "dayjs"
//import { SearchBar } from "../SearchBar";
import { RiMenu2Fill } from "react-icons/ri";
import { BsBell } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getComponentTitle } from "./components/GetComponentTitle";
import logo from "../nav/logo.svg";
import { socketClient } from "../../services/socket";
//import { socketClient } from "../../services/socket";
// import { UserProfileContext } from "../../context/UserContext";

export const Navigation = ({ toggle, showSidebar }) => {
  // const userProfile = useContext(UserProfileContext);
  const [toggleSide] = useState(false);
  //const history = useHistory();

  const location = useLocation();
  const pathname = location.pathname;

  const handleToggle = () => {
    toggle(!toggleSide);
  };

  // const [newIncomingNotification, setNewIncomingNotification] = useState(false);
  // const [
  //   showNewIncomingNotificationDetails,
  //   setShowNewIncomingNotificationDetails,
  // ] = useState(false);
  // const [notificationDetails, setNotificationDetails] = useState({});

  useEffect(() => {
    socketClient.on("in-app-notification", (data) => {
      //play sound when a new notification comes in
      // const audio = new Audio(bellSound);
      // audio.play();
      // setNewIncomingNotification(true);
      // setNotificationDetails(data);
    });
    //eslint-disable-next-line
  }, []);

  return (
    <Box position="relative">
      <Box
        className="navigation-inner"
        fontSize={{ sm: ".9em", md: ".9em", lg: "1em" }}
        px="20px"
        bg="#EFEDF3"
      >
        <Box
          display="flex"
          // gridAutoFlow="column"
          // width="100%"
          // padding="15px 10px"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Flex
            className="brand-style"
            justifyContent={"center"}
            alignItems="center"
          >
            <Flex>
              <Box className="toggle-side-bar" mr="10px">
                {showSidebar && (
                  <RiMenu2Fill
                    color="#000"
                    onClick={handleToggle}
                    size={30}
                    style={{ cursor: "pointer", paddingLeft: "10px" }}
                  />
                )}
              </Box>
            </Flex>

            {/* <Image
            objectFit="cover"
            w="60px"
            h="60px"
            borderRadius="50%"
            src={company?.brandLogo}
            alt="admin"
          /> */}
            <Box mr="40px" cursor={"pointer"}>
              <Link to="/">
                <Image src={logo} alt="logo" />
              </Link>
            </Box>
            <Heading
              fontFamily={"Gilroy-Bold"}
              color="#403058"
              // fontSize={["20px", "25px", "30px"]}
            >
              {getComponentTitle(pathname)}
            </Heading>
          </Flex>

          <Flex justifyContent={["flex-end"]} alignItems="center">
            {/*<Box mr="5%">
              <SearchBar placeholder="search" />
        </Box>*/}
            <Link to="/notifications">
              <Box mr="5%" cursor="pointer">
                <BsBell size={25} color={"#403058"} />
              </Box>
            </Link>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
