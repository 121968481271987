import { Box } from "@chakra-ui/react";
import React from "react";
import { RatingDropdown } from "./Dropdown";

export const FilterArea = ({ searchTerm, setSearchTerm, handleFilter }) => {
  return (
    <Box width={["100%", "100%", "100%"]} justifyContent="space-between">
      <Box>
        <RatingDropdown
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleFilter={handleFilter}
        />
      </Box>
    </Box>
  );
};
