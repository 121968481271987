import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom"
import { loginUser } from "../service/loginUser";
import background from "../images/background.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const LoginForm = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  //  const navigate = useHistory()
  useEffect(() => {
    if (!email || !password) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [email, password]);

  const handleClick = () => setShow(!show);

  const handleLogin = async () => {
    setLoading(true);
    await loginUser({ email, password }, setLoading);
    setLoading(false);
  };

  return (
    <Box>
      <Flex
        width={["100%", "100%", "100%"]}
        direction={["column", "column", "column", "row"]}
      >
        <Box width={["100%"]}>
          <Box px={["50px", "50px"]} pt="50px">
            <Image src={background} />
          </Box>
        </Box>
        <Box width={"100%"} p={["50px", "80px"]}>
          <Heading fontFamily={"Gilroy-Bold"} mb="20px">
            Login
          </Heading>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              borderRadius={"25px"}
              placeholder="Enter Your Email"
              type={show ? "text" : "email"}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl my="20px">
            <FormLabel htmlFor="password">Password</FormLabel>
            <InputGroup>
              <Input
                borderRadius={"25px"}
                type={show ? "text" : "password"}
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement
                top={"auto"}
                children={
                  <span
                    style={{ cursor: "pointer", padding: "5px" }}
                    onClick={handleClick}
                  >
                    {show ? <FaEye /> : <FaEyeSlash />}
                  </span>
                }
              />
            </InputGroup>
          </FormControl>
          <Flex direction={"column"}>
            <Button
              mt="10px"
              bg="#5C2BA8"
              disabled={disabled}
              _hover={{ background: "#5C2BA8" }}
              color="#fff"
              borderRadius={"25px"}
              width="100%"
              isLoading={loading}
              onClick={handleLogin}
            >
              Login
            </Button>
            <Text mt="15px">
              <Link
                to="/forgotpassword"
                style={{ color: "#ED819C", fontSize: ".9em" }}
              >
                Forgot password? Click here
              </Link>
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
