import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import React from "react";

export const Card = ({ tests, name, loadFigures }) => {
  return (
    <>
      <Heading
        fontFamily={"Gilroy-Bold"}
        color={"#636161"}
        fontSize="25px"
        fontWeight={"semibold"}
        py="30px"
      >
        General Report ({name})
      </Heading>
      <Grid
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
        ]}
        gap="1em"
      >
        <Box bg="#FFFFFF" py="20px" px="30px" rounded={"15px"} h="190px">
          <Text
            color="#000000"
            fontSize="18px"
            fontWeight={"bold"}
            isTruncated
            w="100%"
          >
            Total Test Taken
          </Text>
          {loadFigures ? (
            "Loading...."
          ) : (
            <Text color="#47248E" fontWeight={"bold"} fontSize="95px">
              {tests?.totalTestTaken || "--"}
            </Text>
          )}
        </Box>

        <Box bg="#FFFFFF" p="20px" rounded={"15px"} h="190px">
          <Text
            color="#000000"
            fontSize="18px"
            fontWeight={"bold"}
            isTruncated
            w="100%"
          >
            Total Patient That Took Test
          </Text>
          {loadFigures ? (
            "Loading..."
          ) : (
            <Text color="#47248E" fontWeight={"bold"} fontSize="95px">
              {tests?.totalPatientThatTookTest || "--"}
            </Text>
          )}
        </Box>

        <Box bg="#FFFFFF" p="20px" rounded={"15px"} h="190px">
          <Text
            color="#000000"
            fontSize="18px"
            fontWeight={"bold"}
            isTruncated
            w="100%"
          >
            Sessions Booked After Test
          </Text>
          <Flex align={"baseline"}>
            {loadFigures ? (
              "Loading..."
            ) : (
              <>
                <Text color="#00B3B3" fontWeight={"bold"} fontSize="95px">
                  {tests?.sessionBookedAfterTest >= 0
                    ? tests?.sessionBookedAfterTest
                    : "--"}
                </Text>
                <Text color="#707070" fontWeight={"semibold"} fontSize="20px">
                  Sessions
                </Text>
              </>
            )}
          </Flex>
        </Box>
      </Grid>
    </>
  );
};
