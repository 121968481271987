import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

export const ConsultationCard = ({ patientsCase, visitationType, date, endTime, key }) => {
  return (
    <Box bg="#fff" color="#929292" p="8px" borderRadius="10px">
      <Flex width={"100%"} justifyContent="space-between">
        <Box>
          <Text fontSize={"12px"}>{patientsCase}</Text>
          <Text fontSize={"12px"}>{visitationType}</Text>
        </Box>
        <Box>
          <Text fontSize={"11px"}>{date} - {endTime}</Text>
        </Box>
      </Flex>
    </Box>
  );
}
