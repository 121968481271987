import http, { AUTH_ROUTES } from "../../../services/api";

export const getMonthlyTest = async (setTest) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_MONTHLY_TEST);
    setTest(result?.data?.monthlyTestRecord?.data[0]?.count);
  } catch (e) {
    return null;
  }
};

export const getMonthlyUser = async (setUser) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_MONTHLY_USER);
    setUser(result?.data?.monthlyTestRecord?.data[0]?.count);
  } catch (e) {
    return null;
  }
};

export const getMonthlyPayout = async (setMonthlyPayout) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_PAYOUTS);
    setMonthlyPayout(
      result?.data?.getAllWithdrawal?.data?.monthlyRecord[0]?.count
    );
  } catch (e) {
    return null;
  }
};

export const getConsultations = async (filter, setConsultation) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_CONSULTATION);
    if (filter === "all") {
      setConsultation(result?.data?.search?.data?.totalConsultation);
    } else if (filter === "monthly") {
      setConsultation(result?.data?.search?.data?.monthlyRecord?.[0]?.count);
    } else {
      setConsultation(0);
    }
  } catch (e) {
    return null;
  }
};

//?.data?.totalConsultation
