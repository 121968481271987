import http, { AUTH_ROUTES } from "../../../services/api";

export const getMonthlyDoctors = async (setMonthly) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_MONTHLY_DOCTORS);
    setMonthly(result?.data?.monthlyDoctorRecord?.data?.totalData);
  } catch (e) {
    return null;
  }
};
