import React from "react";
import { Tr, Td } from "@chakra-ui/react";
// import { BiDotsHorizontalRounded } from "react-icons/bi"
import { DoctorsMenu } from "./DoctorsMenu";

export const TableBody = ({ getData, setRefresh }) => {
  return (
    <>
      {getData.map((data, i) => {
        return (
          <Tr
            key={i}
            bg="#fff"
            color="#565758"
            fontWeight="bold"
            borderBottom="15px solid #EFEDF3"
            _last={{ borderBottom: "none" }}
          >
            <Td
              color="#575757"
              fontSize={["12px", "12px", "16px"]}
              fontWeight={800}
            >
              {data?.name}
            </Td>
            <Td
              color="#929292"
              fontSize={["12px", "12px", "14px"]}
              fontWeight={600}
            >
              {data?.phoneNumber}
            </Td>

            <Td
              color="#929292"
              fontSize={["12px", "12px", "14px"]}
              fontWeight={600}
            >
              {data?.email}{" "}
            </Td>
            <Td
              color="#929292"
              fontSize={["12px", "12px", "14px"]}
              fontWeight={600}
            >
              {data?.address}
            </Td>
            <Td
              color="#929292"
              fontSize={["12px", "12px", "14px"]}
              fontWeight={600}
            >
              {data?.specialty}
            </Td>

            <Td py="25px !important" _last={{ py: "25px !important" }}>
              <DoctorsMenu data={data} setRefresh={setRefresh} />
            </Td>
          </Tr>
        );
      })}
    </>
  );
};
