import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const deleteDoctors = async (userId) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_DOCTOR(userId));
    successNotifier("Therapist deleted successfully");
  } catch (e) {
    errorNotifier(e.response?.data?.message);
  }
};
