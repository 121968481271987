import React, { useCallback, useEffect, useState } from "react";
import { Flex, Progress } from "@chakra-ui/react";
import { TableBody } from "./components/TableBody";
import { PaginatedTable } from "../../components/PaginatedTable";
// import { tableBodyData } from "./components/tableBodyData";
import { tableHeadData } from "./components/tableHeadData";
import { TestRow } from "./components/TestRow";
import { getAllUsers } from "./service/getUsers";
import FullPageLoader from "../../components/fullPageLoader";
import { searchUsers } from "./service/searchUsers";
import debounce from "lodash.debounce";
//import { errorNotifier } from "../../components/NotificationHandler";

export const Users = () => {
  // const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  // const [filter, setFilter] = useState({ sort: "desc", limit });

  //  How i am handling search

  const handleSearch = (keyword) => {
    setSearchTerm(keyword);
    searchUsers(keyword, setLoading, setFilteredUsers);
  };

  const getUsersData = useCallback(() => {
    setLoading(true);
    getAllUsers()
      .then((data) => {
        setUsers({ ...data });
        // setTestTotal(data.totalTests)
      })
      .catch((e) => {
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  //wait for user to finish typing before sending request
  const processSearch = debounce((res) => {
    handleSearch(res);
  }, 1000);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Flex
      w="100%"
      direction={["column", "column", "column"]}
      justifyContent="space-between"
      p="10px"
    >
      <TestRow
        dataRow={users || []}
        total={users?.totalUsers}
        setRefresh={getUsersData}
        searchTerm={searchTerm}
        setSearchTerm={(search) => {
          setSearchTerm(search);
          processSearch(search);
        }}
        //  total={users}
      />
      {loading ? (
        <Progress isIndeterminate />
      ) : (
        <PaginatedTable
          columns={tableHeadData}
          total={users?.totalUsers}
          length={users?.users?.length}
          bg="#EFEDF3"
          // updateTable={paginate}
          data={
            <TableBody
              getData={
                filteredUsers?.length > 0 ? filteredUsers : users?.users || []
              }
              setRefresh={getUsersData}
            />
          }
          updateTable={(page) => getAllUsers(page)}
        />
      )}
    </Flex>
  );
};
