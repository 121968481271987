import io from "socket.io-client";

export let socketClient;

export async function connect(data) {
  socketClient = await io.connect("https://backend.howbodi.io");
  socketClient.on("connect", (socket) => {
    socketClient.emit("join", data);

    socketClient.on("join", (data) => {});
  });
}

export function disconnect() {
  if (socketClient) {
    socketClient.disconnect();
  }
}
