import { Box } from "@chakra-ui/react";
import React from "react";
import { NotificationCard } from "./NotificationCard";

export const AllNotification = ({ notifications, setRefresh }) => {
  return (
    <Box width={["100%", "100%", "60%"]}>
      {notifications.map((data, i) => {
        return (
          <Box key={i} my="0px" bg="#F6F4F9" borderRadius={"20px"}>
            <NotificationCard data={data} setRefresh={setRefresh} />
          </Box>
        );
      })}
    </Box>
  );
};
