/**
 * Paths available to users who are not logged in
 * @constant
 */
export const PUBLIC_PATHS = {
  LANDING: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgotpassword",
};

/**
 * Paths available to users who are  logged in
 * @constant
 */
export const PROTECTED_PATHS = {
  DASHBOARD: "/dashboard",
  TESTREPORT: "/testReport",
  FINANCE: "/finance",
  USERS: "/users",
  DOCTORS: "/doctors",
  NOTIFICATIONS: "/notifications",
  ADDICTION: "/take-test/addiction",
  TEST_TITLE: "/take-test/:name",
  INVITE: "/take-test/invite",
  CHATS: "/chats",
  REVIEWS: "/reviews",
  TESTUPLOAD: "/testUpload",
  SUBSCRIPTION: "/subscription",
  MEETINGS: "/meetingSummary",
  GENERALREPORT: "/generalReport/:name/:testId",
  //VIDEOUPLOAD: "/VideoUpload"
};

export const DATA_ROWS = {
  LIMIT: 20,
};
