import { Box, Button, Select, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { AiFillRightCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

export const Card = ({
  bg,
  color,
  payment,
  paymetCaption,
  bgImg,
  bgPosition,
  display,
  unit,
  p,
  setFilter,
  noNairaSymbol,
}) => {
  return (
    <Box
      borderRadius={"10px"}
      px={p || "20px"}
      py={"30px"}
      bg={bg}
      bgImage={bgImg}
      width="100%"
      bgRepeat={"no-repeat"}
      bgPosition={bgPosition || "none"}
    >
      <Select
        size="xs"
        width="105px"
        maxW="105px"
        color={color || "#C8B6E2"}
        border="none"
        _focus={{ boxShadow: "none" }}
        fontSize={"14px"}
        onChange={(e) => setFilter(e.target.value)}
      >
        <option value={"all"}>Total</option>
        <option value={"monthly"}>This month</option>
      </Select>
      <Box mt="12px">
        <Tooltip label={payment}>
          <Text
            isTruncated
            w="300px"
            maxW={"300px"}
            color={color || "#fff"}
            fontSize={"40px"}
            fontWeight="bold"
            fontFamily={"Gilroy-Bold"}
          >
            {!noNairaSymbol && "₦"} {payment?.toLocaleString()}
          </Text>
        </Tooltip>
        <Text color={color || "#C8B6E2"} fontSize="16px">
          {paymetCaption}
        </Text>
      </Box>
      <Box display={display || "none"} my="35px">
        <Link to="/finance">
          <Button
            rounded={"full"}
            fontSize="12px"
            h="28px"
            color={"#929292"}
            pr="1"
          >
            More{" "}
            <AiFillRightCircle
              style={{ marginLeft: "10px", color: "#5C2BA8" }}
              size="1.8em"
            />
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
