import { errorNotifier } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const loginUser = async (payload, setLoading) => {
  try {
    const result = await http.post(AUTH_ROUTES.LOGIN, payload);
    console.log(result);
    const token = result?.data?.admin?.message?.token
    if (token){
      localStorage.setItem("HBA#221#", token);
    window.location.href = "/dashboard";
    } else {
      errorNotifier(result?.data?.admin.message);
  }
  } catch (e) {
    setLoading(false);
    errorNotifier(e?.response?.data?.data);
  }
};
