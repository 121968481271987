import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { TableBody } from "./components/TableBody";
import { PaginatedTable } from "../../components/PaginatedTable";
// import { tableBodyData } from "./components/tableBodyData";
import { tableHeadData } from "./components/tableHeadData";
import { TestRow } from "./components/TestRow";
import { getDoctors, searchDoctor } from "./service/getDoctors";
import FullPageLoader from "../../components/fullPageLoader";
import debounce from "lodash.debounce";
//import { CurveBox } from "../../components/CurveBox";

export const Doctors = () => {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [doctors, setDoctors] = useState([]);
  const [totalDoctors, setTotalDoctors] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [doctorsForMemory, setDoctorsForMemory] = useState([]);

  useEffect(() => {
    if (!searchTerm) {
      setDoctors(doctorsForMemory);
    }
  }, [searchTerm, doctorsForMemory]);

  const handleSearch = (keyword) => {
    if (!keyword) {
      setDoctors(doctorsForMemory);
      return;
    }

    searchDoctor(keyword, setDoctors);
  };

  useEffect(() => {
    getDoctors(setDoctors, setDoctorsForMemory, setTotalDoctors, setLoading);
  }, [refresh]);

  //wait for user to finish typing before sending request
  const processSearch = debounce((res) => {
    handleSearch(res);
  }, 1000);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box
      direction={["column", "column", "column"]}
      p="10px"
      bg="#F6F4F9"
      rounded="lg"
    >
      <TestRow
        dataRow={doctors}
        searchTerm={searchTerm}
        setSearchTerm={(search) => {
          setSearchTerm(search);
          processSearch(search);
        }}
        setRefresh={setRefresh}
      />
      <PaginatedTable
        columns={tableHeadData}
        bg="#EFEDF3"
        data={
          <TableBody
            getData={doctors?.length > 0 ? doctors : doctorsForMemory || []}
            setRefresh={setRefresh}
          />
        }
        length={doctors?.length}
        total={totalDoctors}
        updateTable={(page) =>
          getDoctors(setDoctors, setDoctorsForMemory, setTotalDoctors, setLoading, page)
        }
      />
    </Box>
  );
};
