import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FullPageLoader from "../../components/fullPageLoader";
import { PaginatedTable } from "../../components/PaginatedTable";
import { FilterArea } from "./components/FilterArea";
import { PatientReview } from "./components/PatientReview";
import { tableHead } from "./components/tableHead";
import { getReviews, searchDoctorRating } from "./services";
import debounce from "lodash.debounce";

export const Reviews = () => {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalReviews, setTotalReviews] = useState(0);
  const [oldReviewsData, setOldReviewsData] = useState([]);

  useEffect(() => {
    getReviews()
      .then((data) => {
        setReviews(data?.ratings);
        setOldReviewsData(data?.ratings);
        setTotalReviews(data?.totalRatings);
      })
      .catch((e) => {
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSearch = (keyword) => {
    if (!keyword) {
      setReviews(oldReviewsData);
      return;
    }

    searchDoctorRating(setReviews, keyword);
  };

  const handleFilter = (e) => {
    if (!e.target.value) {
      setReviews(oldReviewsData);
      return;
    }
    const filtered = oldReviewsData?.filter(
      (star) => parseInt(star?.ratings) === parseInt(e.target.value)
    );
    setReviews(filtered);
  };
  //wait for user to finish typing before sending request
  const processSearch = debounce((res) => {
    handleSearch(res);
  }, 1000);

  return (
    <Box>
      <FilterArea
        searchTerm={searchTerm}
        setSearchTerm={(search) => {
          setSearchTerm(search);
          processSearch(search);
        }}
        handleFilter={handleFilter}
      />

      <Box width="100%">
        {loading ? (
          <FullPageLoader />
        ) : (
          <PaginatedTable
            columns={tableHead}
            columnColor={"#5C2BA8"}
            bg="#F6F4F9"
            h={"100%"}
            data={<PatientReview getData={reviews.length > 0 ? reviews : []} />}
            total={totalReviews}
            length={reviews?.length}
            updateTable={(page) =>
              getReviews(page)
                .then((data) => {
                  setReviews(data?.ratings);
                  setOldReviewsData(data?.ratings);
                  setTotalReviews(data?.totalRatings);
                })
                .catch((e) => {
                  return null;
                })
                .finally(() => {
                  setLoading(false);
                })
            }
          />
        )}
      </Box>
    </Box>
  );
};
