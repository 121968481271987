import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  //  useDisclosure,
  Box,
  //  Button,
  MenuDivider,
  useDisclosure,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  ModalCloseButton,
  ModalBody,
  Flex,
  Button,
  Tooltip,
  Text,
  //  Tooltip,
  //  Text
} from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai";
// import { FaEdit } from "react-icons/fa"
import { BiDotsHorizontalRounded } from "react-icons/bi";
import CautionAlertDialog from "../../../components/CautionDialog";
// import { UpdateDoctorsModal } from './UpdateDoctorsModal'
import { deleteDoctors } from "../service/deleteDoctors";
import { FiEdit } from "react-icons/fi";
import { useState } from "react";
import { updateDoctor } from "../service/addDoctor";
import { Formcontrol } from "./Formcontrol";

export const DoctorsMenu = ({ data, setRefresh }) => {
  const handleDelete = () => {
    deleteDoctors(data?._id)
      .then(() => {
        setRefresh(true);
      })
      .catch((e) => {
        return null;
      });
  };
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton isActive={isOpen} as={Box} cursor="pointer">
            {/* {isOpen ? 'Close' : 'Open'} */}
            <BiDotsHorizontalRounded size="26px" cursor={"pointer"} />
          </MenuButton>
          <MenuList>
            {/* COMMENTED OUT BECAUSE ADMIN SHOULDNT BE ABLE TO EDIT A DOCTOR'S PROFILE */}
            {/* <MenuItem>
            <UpdateDoctorsModal icon={<AiOutlineDelete size={"18px"} />} data={data} setRefresh={setRefresh} />
          </MenuItem> */}
            <EditModal data={data} setRefresh={setRefresh} />

            <MenuDivider />

            <MenuItem
            // icon={<AiOutlineDelete size={"18px"} />}
            // onClick={() => handleDelete(data._id)}
            >
              <CautionAlertDialog
                button={
                  <Tooltip label="delete therapist" aria-label="A tooltip">
                    <Flex gap="10px">
                      <AiOutlineDelete size={"18px"} />

                      <Text fontWeight={"500"}>Delete</Text>
                    </Flex>
                  </Tooltip>
                }
                cautionTitle="Are you sure you want to delete this therapist?"
                mt="5px"
                small={true}
                onContinue={() => handleDelete(data._id)}
              />
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export const EditModal = ({ data, setRefresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState(data?.name);
  const [email, setEmail] = useState(data?.email);
  const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber);
  const [address, setAddress] = useState(data?.address);
  const [specialty, setSpecialty] = useState(data?.specialty);
  const [loading, setLoading] = useState(false);

  const handleUpdate = () => {
    const payload = {
      email,
      address,
      name,
      specialty,
      phoneNumber,
    };
    setLoading(true);

    updateDoctor(data?._id, payload, setLoading)
      .then(() => {
        setRefresh({});
        setLoading(false);
        onClose();
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return (
    <>
      <MenuItem icon={<FiEdit size={"18px"} />} onClick={onOpen}>
        Edit
      </MenuItem>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Therapist Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              direction={["column", "column", "row"]}
              width={"100%"}
              justifyContent="space-between"
              mb="20px"
            >
              <Box width={["100%", "100%", "48%"]}>
                <Formcontrol
                  placeholder={"Enter your name"}
                  name={"Name"}
                  type="text"
                  value={name}
                  setValue={setName}
                />
              </Box>
              <Box width={["100%", "100%", "48%"]}>
                <Formcontrol
                  placeholder={"Enter your email"}
                  name={"Email"}
                  type="email"
                  value={email}
                  setValue={setEmail}
                />
              </Box>
            </Flex>
            <Flex
              direction={["column", "column", "row"]}
              width={"100%"}
              justifyContent="space-between"
            >
              <Box width={["100%", "100%", "48%"]}>
                <Formcontrol
                  name={"Phone Number"}
                  placeholder={"Enter your phone number"}
                  value={phoneNumber}
                  setValue={setPhoneNumber}
                />
              </Box>
              <Box width={["100%", "100%", "48%"]}>
                <Formcontrol
                  placeholder={"Enter your address"}
                  name="Address"
                  value={address}
                  setValue={setAddress}
                />
              </Box>
            </Flex>
            <Flex
              direction={["column", "column", "row"]}
              width={"100%"}
              justifyContent="space-between"
              alignItems={"center"}
              my="20px"
            >
              <Box width={["100%", "100%", "48%"]}>
                <Formcontrol
                  name={"Speciality"}
                  placeholder={"Enter your speciality"}
                  value={specialty}
                  setValue={setSpecialty}
                />
              </Box>
              <Box width={["100%", "100%", "48%"]} mt={[0, 0, "33px"]}>
                <Button
                  bg="#5C2BA8"
                  color="#fff"
                  _hover={{ bg: "#5C2BA8" }}
                  width="100%"
                  textAlign={"center"}
                  mr={3}
                  isLoading={loading}
                  onClick={() => handleUpdate()}
                >
                  Update
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
