import { DATA_ROWS } from "../../../app/constants";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getTestTaken = async (filter) => {
  if (filter === "all") {
    const result = await http.get(AUTH_ROUTES.GET_TEST_TAKEN);
    return result?.data?.getAllTestTaken?.data?.[0]?.sum_value;
  } else if (filter === "monthly") {
    const result = await http.get(AUTH_ROUTES.GET_TEST_TAKEN_MONTHLY);

    return result?.data?.monthlyTestRecord?.data?.[0]?.count;
  } else {
    return 0;
  }
};

export const getVideo = async (
  setVideo,
  setLoading,
  skip = 0,
  limit = DATA_ROWS.LIMIT
) => {
  const page = limit * skip;
  const params = {
    skip: page,
    limit,
  };
  try {
    const result = await http.get(AUTH_ROUTES.GET_VIDEO(params));
    setVideo(result?.data?.video?.data);

    setLoading(false);
  } catch (e) {
    return null;
  }
};

export const uploadVideo = async (payload) => {
  try {
    await http.post(AUTH_ROUTES.UPLOAD_VIDEO, payload);
    successNotifier("Video successfully added");
  } catch (e) {
    errorNotifier(e.response.message);
  }
};

export const deleteVideo = async (videoId) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_VIDEO(videoId));
    successNotifier("Video Deleted Successfully");
  } catch (e) {
    errorNotifier(e.response?.data?.message);
  }
};

export const updateVideo = async (payload, videoId) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_VIDEO(videoId), payload);
    successNotifier("Video Updated Successfully");
  } catch (e) {
    errorNotifier(e.response?.data?.message);
  }
};
