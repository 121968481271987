import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { AddDoctorsModal } from "./AddDoctorsModal";
import { ExportJsonCsv } from "react-export-json-csv";
import { SearchBar } from "../../../components/SearchBar";
import { HiOutlineDownload } from "react-icons/hi";

export const TestRow = ({
  dataRow,
  searchTerm,
  setSearchTerm,
  handleSearch,
  setRefresh,
}) => {
  const headers = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "phoneNumber",
      name: "Phone Number",
    },
    {
      key: "email",
      name: "Email",
    },
    {
      key: "address",
      name: "Address",
    },
    {
      key: "specialty",
      name: "Speciality",
    },
  ];

  const data = dataRow?.map((item) => {
    return {
      name: item?.name,
      phoneNumber: String(item?.phoneNumber),
      email: item?.email,
      address: item?.address,
      specialty: item?.specialty,
    };
  });

  return (
    <Box>
      <Flex
        direction={["column", "column", "row"]}
        justifyContent={"space-between"}
        alignItems="center"
        my="20px"
        px="20px"
      >
        <Flex
          width={["100%", "100%", "40%"]}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Flex
            direction={["column", "row"]}
            width={["30%", "30%", "35%"]}
            justifyContent={["flex-start", "space-between"]}
            alignItems="center"
            color="#BBBBBB"
          >
            <Text color="#9E8FB5" fontSize={"36px"} fontWeight={"bold"}>
              {dataRow?.length}
            </Text>
            <Text>Therapists</Text>
            <Divider orientation="vertical" />
          </Flex>
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearch={handleSearch}
          />
          {/* <Text color="#BBBBBB">Sort By:</Text> */}
          {/* <Select
            placeholder="Last appointment"
            bg="#fff"
            color="#BBBBBB"
            width={"180px"}
          >
            <option>Last Month</option>
            <option>Last Week</option>
          </Select> */}
        </Flex>
        <Flex justifyContent={"space-between"} width={["100%", "100%", "25%"]}>
          <ExportJsonCsv headers={headers} items={data}>
            <Flex
              justifyContent={"center"}
              alignItems="center"
              bg="#fff"
              p={["3px 8px", "5px 15px"]}
              mr="2px"
              cursor={"pointer"}
            >
              <HiOutlineDownload />
              <Text ml="8px" color="#403058">
                CSV
              </Text>
            </Flex>
          </ExportJsonCsv>

          <AddDoctorsModal setRefresh={setRefresh} />
        </Flex>
      </Flex>
      <Divider />
    </Box>
  );
};
