import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

export const addDoctor = async (payload, setLoading) => {
  try {
    await http.post(AUTH_ROUTES.ADD_DOCTOR, payload);

    successNotifier("Therapist successfully added");
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.data);
  }
};

export const updateDoctor = async (id, payload, setLoading) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_DOCTOR(id), payload);

    successNotifier("successfully updated");
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.data);
  }
};
