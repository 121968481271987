import React from "react";
import { Tr, Td, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";
import { HiOutlineTrash } from "react-icons/hi";
import { EditTestModal } from "./EditTestModal";
import { deleteVideo } from "../services";

export const Table1 = ({ videos, setRefresh }) => {
  const handleDelete = (videoId) => {
    deleteVideo(videoId).then(() => {
      setRefresh(true);
    });
  };
  return (
    <>
      {videos?.map((item, i) => {
        return (
          <Tr
            key={i}
            bg="#FFFFFF"
            color="#352848"
            borderBottom="10px solid #EFEDF3"
          >
            <Td>{i + 1}</Td>
            <Td p="20px !important">{item?.videoTitle}</Td>
            <Td>{dayjs(item?.createdAt).format("DD-MMM-YYYY")}</Td>
            <Td>
              <Flex align="baseline">
                <EditTestModal data={item} setRefresh={setRefresh} />
                <HiOutlineTrash
                  size="1.5rem"
                  color="red"
                  onClick={() => handleDelete(item?._id)}
                />
              </Flex>
            </Td>
          </Tr>
        );
      })}
    </>
  );
};
