import { DATA_ROWS } from "../../../app/constants";
import http, { AUTH_ROUTES } from "../../../services/api";

export const getMeetingsSummary = async () => {
  const result = await http.get(AUTH_ROUTES.GET_TEST_TAKEN);
  return result?.data?.getAllTestTaken?.data;
};

export const getMeetings = async (
  setMeeting,
  setLoading,
  skip = 0,
  limit = DATA_ROWS.LIMIT
) => {
  const page = limit * skip;
  const params = {
    skip: page,
    limit,
  };
  try {
    const { data } = await http.get(AUTH_ROUTES.MEETING_SUMMARY(params));
    setMeeting(data?.meetingSummary?.data);
    setLoading(false);
  } catch (e) {
    setMeeting({ meetingSummaryCount: 0, meetingSummary: [] });
  }
};

export const searchUsers = async (payload, setLoading, setSearchResult) => {
  try {
    const { data } = await http.get(
      AUTH_ROUTES.MEETING_SUMMARY_SEARCH_DOCTOR(payload)
    );
    setLoading(false);
    data?.search?.data.length > 0 ? setSearchResult(data) : setSearchResult([]);
  } catch (e) {
    setLoading(false);
    setSearchResult([]);
  }
};
