import http, { AUTH_ROUTES } from "../../../services/api";

export const getTopDoctors = async () => {
  // let filterString = getFilterString(filter)
  const result = await http.get(AUTH_ROUTES.GET_TOP_DOCTOR);
  return result?.data?.topDoctor?.data?.topDoctors;
};

export const getAppointment = async () => {
  // let filterString = getFilterString(filter)
  const result = await http.get(AUTH_ROUTES.GET_TODAY_APPOINTMENT);
  return result?.data?.patients?.data;
};

export const getTopAssessment = async (setTopAssessment) => {
  const result = await http.get(AUTH_ROUTES.GET_TOP_ASSESSMENT);
  setTopAssessment(result?.data?.assessment?.data);
};
