import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { PayInPayOutCards } from "./components/PayInPayOutCards";
// import { PaginatedTable } from "../../components/PaginatedTable";
// import { Payment } from "./components/Payment";
// import { tableBodyData } from "../doctors/components/tableBodyData";
// import { PayoutRequests } from "./components/PayoutRequests";
import { getPayouts, getWithdrawals } from "./services/getWithdrawals";
import FullPageLoader from "../../components/fullPageLoader";
import { PayoutModal } from "./components/PayoutModal";
import { PaymentCards } from "./components/PaymentCards";
import { PaymentCardsUser } from "./components/PaymentCardsUser";
import { getUserPaid, getUserPayIn } from "./services";
//import { PaginatedTable } from "../../components/PaginatedTable";

export const Finance = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [totalPayouts, setTotalPayouts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState({});
  const [userPayIn, setUserPayIn] = useState([]);
  const [userPaid, setUserPaid] = useState([]);
  const [totalUserPayinFilter, setTotalUserPayinFilter] = useState("all");

  useEffect(() => {
    getPayouts()
      .then((data) => {
        setPayouts([data]);
        setTotalPayouts([data?.totalPayout?.[0]?.TotalAmount]);
      })
      .catch((e) => {});
    getWithdrawals(setWithdrawals, setLoading);
    getUserPayIn(totalUserPayinFilter, setUserPayIn);
    getUserPaid(setUserPaid);
  }, [totalUserPayinFilter, refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width="100%">
      <Flex
        width={"100%"}
        justifyContent="space-between"
        direction={["column", "column", "row"]}
      >
        <Box width={["100%", "100%", "66%"]}>
          <PayInPayOutCards
            payout={totalPayouts}
            monthly={payouts?.[0]?.monthlyRecord?.[0]?.TotalAmount}
            //payouts={payouts}
            userPayIn={userPayIn}
            setTotalUserPayinFilter={setTotalUserPayinFilter}
            // setTotalPayoutFilter={setTotalPayoutFilter}
            // monthlyPayout={monthlyPayout}

            //setPayoutsFilter={setTotalPayoutsFilter}
          />

          <Flex
            justifyContent={"space-between"}
            px={["10px", "10px", "10px"]}
            my="20px"
          >
            <Text mt="20px" fontSize={"22px"} fontWeight="bold" color="#686278">
              Payments
            </Text>
          </Flex>
          <Box width="100%">
            <Tabs variant="soft-rounded" colorScheme="#5C2BA8" width="100%">
              <TabList>
                <Tab
                  mr="12px"
                  bg="#fff"
                  color="#c4c4c4"
                  _selected={{ bg: "#5C2BA8", color: "#FBFBFC" }}
                  w="173px"
                >
                  User paid in
                </Tab>

                <Tab
                  bg="#fff"
                  color="#c4c4c4"
                  _selected={{ bg: "#5C2BA8", color: "#FBFBFC" }}
                  w="173px"
                >
                  Therapist payout
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box h="400px" overflow={"scroll"}>
                    {userPaid?.totalPayin?.data?.pay_in.map((withdrawal) => {
                      return <PaymentCardsUser getData={withdrawal} />;
                    })}
                  </Box>

                  {/* <PaginatedTable
                    columns={[]}
                    bg="#f2f2f2"
                    data={
                      <Payment
                        getData={withdrawals}
                        />
                    }
                    /> */}
                </TabPanel>

                <TabPanel>
                  <Box h="400px" overflow={"scroll"}>
                    {payouts?.map((withdrawal) =>
                      withdrawal?.doctorPayout?.map((withdrawal) => {
                        return <PaymentCards getData={withdrawal} />;
                      })
                    )}
                  </Box>

                  {/* <PaginatedTable
                    columns={[]}
                    bg="#f2f2f2"
                    data={
                      <Payment
                        getData={withdrawals}
                        />
                      }
                        /> */}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
        <Box width={["100%", "100%", "33%"]} mt="-20px">
          {/* <PaginatedTable
            columns={[]}
            bg="#f2f2f2"
            data={
              <PayoutRequests
              setRefresh={setRefresh}
              />
            }
           
          /> */}
          <PayoutModal
            getData={withdrawals}
            setRefresh={(res) => setRefresh(res)}
          />
        </Box>
      </Flex>
    </Box>
  );
};
